import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'


const styles = ( theme ) => ( {
	progress	: {
		marginTop	: '10px'
	}
} )


class ProgressDialog extends React.Component {
	render() {
		if ( !this.props.isOpen ) return ( <div /> )

		return (
			<Dialog
				open={ this.props.isOpen }
			>
				<DialogTitle>{ this.props.dialogTitle }</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{ this.props.dialogText }
					</DialogContentText>
					<LinearProgress className={ this.props.classes.progress } />
				</DialogContent>
			</Dialog>
		)
	}
}


ProgressDialog.propTypes = {
	isOpen			: PropTypes.bool.isRequired,
	dialogTitle		: PropTypes.string.isRequired,
	dialogText		: PropTypes.string.isRequired,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}


export default withStyles( styles )( ProgressDialog )
