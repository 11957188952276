import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import helpers from '../../lib/helpers'
import { withStyles } from '@material-ui/core/styles'


const styles = ( theme ) => ( {
	dialog_actions 				: {
		[theme.breakpoints.down( 'sm' )] : {
			marginBottom	: 40,
			marginRight		: 40
		}
	}
} )


class SimpleConfirmationDialog extends React.Component {
	render() {
		if ( !this.props.isOpen ) return ( <div /> )

		return (
			<Dialog
				open={ this.props.isOpen }
				onClose={ this.props.closeDialog }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle>{ this.props.dialogTitle }</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{ this.props.dialogText }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={ this.props.closeDialog } color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


SimpleConfirmationDialog.propTypes = {
	isOpen			: PropTypes.bool.isRequired,
	dialogTitle		: PropTypes.string.isRequired,
	dialogText		: PropTypes.string.isRequired,
	closeDialog		: PropTypes.func.isRequired,
}


export default withStyles( styles )( SimpleConfirmationDialog )
