import React from 'react'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'

import helpers from '../../lib/helpers'

import { withStyles } from '@material-ui/core/styles'

const styles = ( theme ) => ( {
	edit_tweet_dialog		: {
		minHeight			: '65%',
		minWidth			: '35%',
	}
} )

const TEXT_MAX_LENGTH = 280

class EditTweet extends React.Component {
	constructor( props ) {
		super( props )

		this._window = window

		this.saveEdit = this.saveEdit.bind( this )
		this.cancelEdit = this.cancelEdit.bind( this )
		this.updateTweet = this.updateTweet.bind( this )
	}

	get window() { return this._window }

	async saveEdit() {
		this.props.closeDialog( this.props.post.shortstop.twitter_sharing.body_text )
	}

	cancelEdit() {
		this.props.closeDialog( null )
	}

	updateTweet( event ) {
		let newPost = _cloneDeep( this.props.post )

		if ( event.target.value.length <= TEXT_MAX_LENGTH ) {
			newPost.shortstop.twitter_sharing.body_text = event.target.value
		}

		this.props.updatePostState( newPost )
	}

	render() {
		let error = true
		let helpText = 'Tweet must contain some content'

		if ( this.props.post.shortstop.twitter_sharing.body_text.length > 0 ) {
			error = false
			helpText = `Character count: ${ this.props.post.shortstop.twitter_sharing.body_text.length } (Max: ${ TEXT_MAX_LENGTH })`

			if ( this.props.post.shortstop.twitter_sharing.body_text.length > TEXT_MAX_LENGTH ) error = true
		}

		return (
			<Dialog
				disableBackdropClick
				open={ this.props.isOpen }
				onClose={ this.props.closeDialog }
				classes={ { paper: this.props.classes.edit_tweet_dialog } }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle>EDIT TWEET</DialogTitle>
				<DialogContent>
					<FormControl error={ error } fullWidth>
						<TextField
							className={ this.props.classes.textField }
							placeholder="Tweet about this post..."
							multiline
							maxLength={ TEXT_MAX_LENGTH }
							error={ error }
							value={ this.props.post.shortstop.twitter_sharing.body_text }
							onChange={ this.updateTweet }
						/>
						<FormHelperText>{ helpText }</FormHelperText>
					</FormControl>
				</DialogContent>
				<DialogActions className={ this.props.classes.dialog_actions }>
					<Button variant="contained" onClick={ this.saveEdit } color="primary">
						PUBLISH
					</Button>
					<Button variant="contained" onClick={ this.cancelEdit } color="secondary">
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

EditTweet.propTypes = {
	// component specific
	post			: PropTypes.object.isRequired,
	isOpen				: PropTypes.bool.isRequired,
	closeDialog			: PropTypes.func.isRequired,
	updatePostState		: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( EditTweet )
