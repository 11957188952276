import _get from 'lodash/get'

import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import User from '../../models/user'
import UserMetadata from '../../models/user_metadata'

import BaseAPI from './_api'


export default class UserAPI {
	static getUser( user ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ user._id }`

			BaseAPI.setupRequest( user, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Get ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static updateUser( user ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ user._id }`

			BaseAPI.setupRequest( user, request.put( path ) )
				.send( user )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Update ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static getUserMetadata( currentUser ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ currentUser._id }/metadata`
			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					let statusCode = 500
					if ( helpers.doesExist( res ) ) statusCode = _get( res, 'statusCode', 500 )

					if ( helpers.doesExist( err ) && statusCode !== 404 ) return BaseAPI.handleError( err, res, reject, `Unable to retrieve metadata for user: ${ currentUser.username }` )

					if ( statusCode === 404 ) return resolve( null )
					resolve( new UserMetadata( res.body ) )
				} )
		} )
	}


	static createUserMetadata( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ currentUser._id }/metadata`
			BaseAPI.setupRequest( currentUser, request.post( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Unable to retrieve metadata for user: ${ currentUser.username }` )
					resolve( new UserMetadata( res.body ) )
				} )
		} )
	}


	static updateUserMetadata( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ currentUser._id }/metadata`
			BaseAPI.setupRequest( currentUser, request.put( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Unable to retrieve metadata for user: ${ currentUser.username }` )
					resolve( new UserMetadata( res.body ) )
				} )
		} )
	}
}
