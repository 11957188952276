import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import Media from '../../models/media'

import BaseAPI from './_api'


export default class MediaAPI {
	static searchMedia( currentUser, limit, offset, mediaIds = [], smileContent = false, groupId = null ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/media`

			let queryParams = {
				limit: limit,
				offset: offset,
				media_type: 'shortstop',
				current_state: 'Delivered,Publishing,Published,TranscodingVideo',
				user_id: currentUser._id.toString(),
				smile_content: smileContent
			}

			if ( groupId ) {
				queryParams.group_id = groupId
			}

			if ( mediaIds.length > 0 ) {
				queryParams.media_ids = mediaIds.join( ',' )
			}

			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Search ${ Media.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Media( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static getMedia( currentUser, mediaId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/media/${ mediaId }`

			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Get ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}


	static createMedia( currentUser, media, bulkPost = false ) {
		const endpoint = bulkPost ? 'media/bulk' : 'media'
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/${ endpoint }`

			BaseAPI.setupRequest( currentUser, request.post( path ) )
				.send( media )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Create ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}


	static updateMedia( currentUser, media ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/media/${ media._id }`

			BaseAPI.setupRequest( currentUser, request.put( path ) )
				.send( media )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Update ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}


	static deleteMedia( currentUser, mediaId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/media/${ mediaId }`

			BaseAPI.setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Delete ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}
}
