import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline'

import Media from '../../models/media'

import API from '../../lib/api'
import helpers from '../../lib/helpers'


const styles = ( theme ) => ( {
	uploading_label	: {
		marginTop		: 10,
		marginBottom	: 10,
	},
} )


class FileUpload extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			progress	: 0,
		}

		this.API = API
		if ( props.api ) this.API = props.api

		this.removeAttachment = this.removeAttachment.bind( this )
		this.handleFilePickerChange = this.handleFilePickerChange.bind( this )
	}


	async resizeImage( file, attachment, type ) {
		return new Promise( ( resolve, reject ) => {
			let u = URL.createObjectURL( file )
			let image = new Image()
			image.src = u

			image.onload = function() {
				attachment.formats.forEach( ( element ) => {
					if ( element.display_type === type ) {
						image.width = element.metadata.width
						image.height = element.metadata.height

						resolve( image )
					}
				} )
			}
			image.onerror = function() { reject( new Error( 'Unable to resize image for upload, please try again' ) ) }
		} ).then( ( newImage ) => {
			let canvas = document.createElement( 'canvas' )

			canvas.width = newImage.width
			canvas.height = newImage.height

			canvas.getContext( '2d' ).drawImage( newImage, 0, 0, newImage.width, newImage.height )

			let dataUrl = canvas.toDataURL( file.type )

			// convert base64/URLEncoded data component to raw binary data held in a string
			let byteString = atob( dataUrl.split( ',' )[1] )
			let mimeString = dataUrl.split( ',' )[0].split( ':' )[1].split( ';' )[0]

			// write the bytes of the string to a typed array
			let ia = new window.Uint8Array( byteString.length )
			for ( let i = 0; i < byteString.length; i++ ) {
				ia[ i ] = byteString.charCodeAt( i )
			}

			let blob = new Blob( [ ia ], { type: mimeString } )
			return blob
		} ).catch( ( e ) => {
			this.props.showErrorDialog( 'Attachment Upload', e )
		} )
	}


	async handleSettingImageSize( file, originalImageUrl ) {
		let attachment = Media.createEmptyPhotoAttachment()

		return new Promise( ( resolve, reject ) => {
			let u = URL.createObjectURL( file )

			let image = new Image()

			image.src = u

			let width = 0
			let height = 0
			let thumbnailWidth = 0
			let thumbnailHeight = 0
			let resizedWidth = 0
			let resizedHeight = 0
			let orientation = 'even'
			let longerSide = 0
			let shorterSide = 0

			image.onload = function() {
				if ( image.naturalWidth >= image.naturalHeight ) {
					longerSide = width
					shorterSide = height
					resizedWidth = 1104
					resizedHeight = 621
					thumbnailWidth = 368
					thumbnailHeight = 207
					orientation = 'landscape'
				}
				else if ( image.naturalWidth < image.naturalHeight ) {
					longerSide = height
					shorterSide = width
					resizedHeight = 1104
					resizedWidth = 621
					orientation = 'portrait'
					thumbnailWidth = 207
					thumbnailHeight = 368
				}

				if ( longerSide / shorterSide < 1.4 ) { // 4:3 aspect ratio
					resizedWidth = 1024
					resizedHeight = 768
					thumbnailWidth = 408
					thumbnailHeight = 306

					if ( longerSide === height ) {
						resizedWidth = 768
						resizedHeight = 1024
						thumbnailWidth = 306
						thumbnailHeight = 408
					}
				}

				attachment.formats.forEach( ( item ) => {
					item.content_type = file.type
					item.metadata.orientation_flag = orientation
				} )

				attachment.formats[ 0 ].url = originalImageUrl
				attachment.formats[ 0 ].metadata.height = image.height
				attachment.formats[ 0 ].metadata.width = image.width
				attachment.formats[ 1 ].metadata.width = resizedWidth
				attachment.formats[ 1 ].metadata.height = resizedHeight
				attachment.formats[ 2 ].metadata.width = thumbnailWidth
				attachment.formats[ 2 ].metadata.height = thumbnailHeight

				resolve( attachment )
			}

			image.onerror = function() { reject( new Error( 'Failed to load image, please try again' ) ) }
		} ).then( ( updatedAttachment ) => {
			return updatedAttachment
		} ).catch( ( e ) => {
			this.props.showErrorDialog( 'Attachment Upload', e )
		} )
	}


	async setUpVideoAttachment( url, file ) {
		let attachment = Media.createEmptyVideoAttachment()

		return new Promise( ( resolve, reject ) => {
			let u = URL.createObjectURL( file )

			let video = document.createElement( 'video' )
			video.setAttribute( 'src', u )

			let longerSide = 0
			let shorterSide = 0
			let aspectRatio = '16:9'

			let orientation = 'landscape'
			let duration = '00:00:00'

			video.onloadedmetadata = () => {
				duration = video.duration

				if ( video.videoWidth >= video.videoHeight ) {
					longerSide = video.videoWidth
					shorterSide = video.videoHeight
					orientation = 'landscape'
				}
				else {
					longerSide = video.videoHeight
					shorterSide = video.videoWidth
					orientation = 'portrait'
				}

				if ( longerSide / shorterSide < 1.4 ) { // 4:3 aspect ratio
					aspectRatio = '4:3'
				}

				attachment.formats[ 0 ].url = url
				attachment.formats[ 0 ].content_type = file.type
				attachment.formats[ 0 ].display_type = 'original'
				attachment.formats[ 0 ].metadata.height = video.videoHeight
				attachment.formats[ 0 ].metadata.width = video.videoWidth
				attachment.formats[ 0 ].metadata.duration = duration
				attachment.formats[ 0 ].metadata.orientation_flag = orientation
				attachment.formats[ 0 ].metadata.aspect_ratio = aspectRatio

				resolve( attachment )
			}

			video.onerror = () => { reject( new Error( 'Failed to load video, please try again' ) ) }
		} ).then( ( updatedAttachment ) => {
			return updatedAttachment
		} ).catch( ( e ) => {
			this.props.showErrorDialog( 'Attachment Upload', e )
		} )
	}


	async getImageFromVideo( file ) {
		return new Promise( ( resolve, reject ) => {
			let fileReader = new FileReader()

			fileReader.onload = () => {
				let blob = new Blob( [ fileReader.result ], { type: file.type } )
				let url = URL.createObjectURL( blob )
				let video = document.createElement( 'video' )
				let finalImage = null
				let timeupdate = () => {
					if ( snapImage() ) {
						video.removeEventListener( 'timeupdate', timeupdate )
						video.pause()
						resolve( finalImage )
					}
				}

				let snapImage = () => {
					let canvas = document.createElement( 'canvas' )
					canvas.width = video.videoWidth
					canvas.height = video.videoHeight
					canvas.getContext( '2d' ).drawImage( video, 0, 0, canvas.width, canvas.height )
					finalImage = canvas.toDataURL()
					let success = finalImage.length > 100000
					if ( success ) {
						URL.revokeObjectURL( url )
					}

					return success
				}

				video.addEventListener( 'timeupdate', timeupdate )
				video.src = url
				video.preload = 'metadata'
				video.muted = true
				video.playsInline = true
				video.play()
			}

			fileReader.readAsArrayBuffer( file )

			fileReader.onerror = () => { reject( new Error( 'Failed to get image from video, please try again' ) ) }
		} ).then( ( dataUrl ) => {
			// convert base64/URLEncoded data component to raw binary data held in a string
			let byteString = atob( dataUrl.split( ',' )[ 1 ] )
			let mimeString = dataUrl.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ]

			// write the bytes of the string to a typed array
			let ia = new window.Uint8Array( byteString.length )
			for ( let i = 0; i < byteString.length; i++ ) {
				ia[i] = byteString.charCodeAt( i )
			}

			let blob = new Blob( [ ia ], { type:mimeString } )
			return blob
		} ).catch( ( e ) => {
			this.props.showErrorDialog( 'Attachment Upload', e )
		} )
	}


	async uploadToAws( workItem, file, type ) {
		return new Promise( async ( resolve, reject ) => {
			try {
				let response = await this.API.AWS.getPreSignedUrl( this.props.currentUser, workItem )
				if ( !response || response.statusCode !== 200 ) throw new Error( 'Invalid pre-signed URL' )

				let preSignedUrl = JSON.parse( JSON.stringify( response.body ) ).url
				let fileUrl = preSignedUrl.split( '?' )[ 0 ]

				await this.API.AWS.uploadToAws( this.props.currentUser, file, preSignedUrl, type )

				resolve( fileUrl )
			}
			catch ( e ) {
				reject( e )
			}
		} )
	}


	async uploadVideo( workItem, file, fileType ) {
		return new Promise( async ( resolve, reject ) => {
			this.setState( { progress: 5 }, async () => {
				try {
					let videoUrl = await this.uploadToAws( workItem, file, file.type )
					if ( helpers.doesNotExist( videoUrl ) ) return

					let videoAttachment = await this.setUpVideoAttachment( videoUrl, file )
					if ( helpers.doesNotExist( videoAttachment ) ) return

					let image = await this.getImageFromVideo( file )
					if ( helpers.doesNotExist( image ) ) return

					this.setState( { progress: 75 }, async () => {
						try {
							// now handle image
							let newWorkItem = JSON.stringify( {
								'expiration': 60 * 15,
								'attachment_content_type': 'image/png'
							} )

							let newType = 'image/png'

							let imageAttachment = await this.uploadImage( newWorkItem, image, newType, true )
							resolve( [ videoAttachment, imageAttachment ] )
						}
						catch ( e ) {
							reject( e )
						}
					} )
				}
				catch ( e ) {
					reject( e )
				}
			} )
		} )
	}


	async uploadImage( workItem, file, fileType, isVideoThumbnail ) {
		return new Promise( async ( resolve, reject ) => {
			let progressSteps = [ 30, 60, 100 ]
			if ( isVideoThumbnail ) progressSteps = [ 80, 85, 100 ]

			try {
				let originalUrl = await this.uploadToAws( workItem, file, fileType )
				if ( helpers.doesNotExist( originalUrl ) ) return

				this.setState( { progress: progressSteps[ 0 ] }, async () => {
					try {
						let attachment = await this.handleSettingImageSize( file, originalUrl )
						if ( helpers.doesNotExist( attachment ) ) return

						let resizedImage = await this.resizeImage( file, attachment, 'resized' )
						if ( helpers.doesNotExist( resizedImage ) ) return

						let resizedUrl = await this.uploadToAws( workItem, resizedImage, fileType )
						if ( helpers.doesNotExist( resizedUrl ) ) return

						attachment.formats[ 1 ].url = resizedUrl

						this.setState( { progress: progressSteps[ 1 ] }, async () => {
							try {
								let thumbnailImage = await this.resizeImage( file, attachment, 'thumbnail' )
								if ( helpers.doesNotExist( thumbnailImage ) ) return

								let thumbnailUrl = await this.uploadToAws( workItem, thumbnailImage, fileType )
								if ( helpers.doesNotExist( thumbnailUrl ) ) return

								attachment.formats[ 2 ].url = thumbnailUrl

								this.setState( { progress: progressSteps[ 2 ] }, () => {
									resolve( attachment )
								} )
							}
							catch ( e ) {
								reject( e )
							}
						} )
					}
					catch ( e ) {
						reject( e )
					}
				} )
			}
			catch ( e ) {
				reject( e )
			}
		} )
	}


	async handleFilePickerChange( event, index, value ) {
		await this.props.startUpload()

		this.setState( { progress: 0 }, async () => {
			let fileList = this.fileUpload.files

			if ( helpers.doesNotExist( fileList ) ) {
				await this.props.finishUpload( [] )
				return this.props.showErrorDialog( 'Attachment Selection', new Error( 'Unable to obtain local file, please try again.' ) )
			}

			try {
				let attachments = []

				let fileListKeys = Object.keys( fileList )

				for ( let index = 0; index < fileListKeys.length; index++ ) {
					let type = fileList[index].type

					if ( !helpers.isValidAssetType( type ) ) {
						await this.props.finishUpload( [] )
						return this.props.showErrorDialog( 'Attachment Selection', new Error( 'Check that the file type is mov/mp4/jpg/jpeg/png' ) )
					}

					let workItem = JSON.stringify( {
						'expiration' : 60 * 15,
						'attachment_content_type' : type
					} )

					if ( type.includes( 'video' ) ) {
						if ( this.props.post.smile_content ) {
							attachments.push( await this.uploadVideo( workItem, fileList[index], type, false ) )
						}
						else {
							attachments = await this.uploadVideo( workItem, fileList[index], type, false )
						}
					}
					else {
						attachments.push( await this.uploadImage( workItem, fileList[index], type, false ) )
					}

					if ( fileList.length === parseInt( index ) + 1 ) {
						await this.props.finishUpload( attachments )
					}
				}
			}
			catch ( e ) {
				await this.props.finishUpload( [] )
				this.props.showErrorDialog( 'Attachment Upload', e )
				this.setState( { uploading: false } )
			}
		} )
	}


	removeAttachment( index = null ) {
		this.props.removeAttachment( index )

		/** Clear the input so it lets upload same image that was removed */
		if ( this.props.post.attachments.length <= 1 && helpers.doesExist( this.fileUpload ) ) {
			this.fileUpload.value = ''
		}
	}


	renderAttachmentSummaryForSmile() {
		return (
			<>
				{
					this.props.post.attachments.map( ( attachment, index ) => {
						return (
							<div key={ index } style={ { display: 'flex', alignItems: 'center' } }>
								<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textSecondary" noWrap>
									{ `Attached ${ attachment.kind ? attachment.kind : 'video' } (${ parseInt( index ) + 1 })` }
								</Typography>

								<div style={ { marginLeft: 'auto', flex: 'none' } }>
									<IconButton
										variant="fab"
										onClick={ () => this.removeAttachment( index ) }
										disabled={ this.props.disabled }
									>
										<RemoveCircleIcon />
									</IconButton>
								</div>
							</div>
						)
					} )
				}
			</>
		)
	}

	renderAttachmentSummary() {
		return (
			<div style={ { display: 'flex', alignItems: 'center' } }>
				<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textSecondary" noWrap>
					{ `Attached ${ this.props.post.shortstop.media_type }` }
				</Typography>

				<div style={ { marginLeft: 'auto', flex: 'none' } }>
					<IconButton variant="fab" onClick={ () => this.removeAttachment() }>
						<RemoveCircleIcon />
					</IconButton>
				</div>
			</div>
		)
	}

	renderFileUpload() {
		let progress = ( <div /> )
		if ( this.props.uploading ) {
			progress = (
				<div>
					<Typography variant="caption" color="textSecondary" noWrap className={ this.props.classes.uploading_label }>
						Uploading...
					</Typography>

					<LinearProgress variant="determinate" color="secondary" value={ this.state.progress } />
				</div>
			)
		}

		return (
			<div>
				<Button
					fullWidth
					variant="outlined"
					color="secondary"
					component="span"
					disabled={ this.props.uploading || this.props.disabled }
					onClick={ () => this.fileUpload.click() }
				>
					Choose File
				</Button>

				{
					this.props.errorMessage && <FormHelperText error>{ this.props.errorMessage }</FormHelperText>
				}

				<input
					disabled={ this.props.disabled }
					type="file"
					multiple={ this.props.post.smile_content }
					ref={ ( fileUpload ) => { this.fileUpload = fileUpload } }
					onChange={ this.handleFilePickerChange } style={ { visibility: 'hidden' } }
				/>
				{ progress }
			</div>
		)
	}


	render() {
		let renderAttachments = <div />
		let renderUploadButton = <div />

		if ( this.props.post.attachments.length > 0 ) {
			renderAttachments = this.props.post.smile_content ? this.renderAttachmentSummaryForSmile() : this.renderAttachmentSummary()
		}

		if ( this.props.post.attachments.length === 0 || this.props.post.smile_content ) {
			renderUploadButton = this.renderFileUpload()
		}

		return (
			<>
				{ renderUploadButton }
				{ renderAttachments }
			</>
		)
	}
}


FileUpload.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	showErrorDialog		: PropTypes.func.isRequired,
	errorMessage		: PropTypes.string,

	// component specific
	post				: PropTypes.object.isRequired,
	uploading			: PropTypes.bool.isRequired,
	startUpload			: PropTypes.func.isRequired,
	finishUpload		: PropTypes.func.isRequired,
	removeAttachment	: PropTypes.func.isRequired,
	disabled			: PropTypes.bool,

	// test hooks
	api					: PropTypes.object,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,
}

FileUpload.defaultProps = {
	disabled: false
}

export default withStyles( styles )( FileUpload )
