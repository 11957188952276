import moment from 'moment'
import Cookies from 'js-cookie'

import config from './config'
import helpers from './helpers'


export default class Analytics {
	static clearUserActivity() {
		Cookies.remove( 'session' )
	}


	static recordUserActivity() {
		Cookies.set( 'session', { last_updated: moment().format() } )
	}


	static get sessionInfo() {
		return Analytics.getSessionInfo( Cookies.get( 'session' ) )
	}


	static getSessionInfo( sessionCookie ) {
		if ( config.environment === 'local' ) return { isExpired: false }
		if ( helpers.doesNotExist( sessionCookie ) ) return { isExpired: true }

		let startMoment = moment( JSON.parse( sessionCookie ).last_updated, moment.ISO_8601 )
		let endMoment = moment()

		let msSinceLastAction = moment.duration( endMoment.diff( startMoment ) ).asMilliseconds()

		let isExpired = false
		if ( msSinceLastAction >= config.idleTimeoutInMS ) isExpired = true

		return { isExpired: isExpired, minutesSinceLastAction: moment.duration( endMoment.diff( startMoment ) ).asMinutes() }
	}
}
