import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Button from '@material-ui/core/Button/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import BulletPointIcon from '@material-ui/icons/Remove'
import ListSubheader from '@material-ui/core/ListSubheader'

import config from '../lib/config'
import helpers from '../lib/helpers'

const DIALOG_WIDTH = 300

const DATE_RELEASED = 'May 22, 2023'
const NEW_FEATURES = [
	'Upgrade Node version up to 12'
]


const styles = ( theme ) => ( {
	content				: {
		display				: 'flex',
		flexDirection		: 'column',
		[theme.breakpoints.down( 'up' )] : {
			minWidth: DIALOG_WIDTH
		}
	},
	content_upper_row	: {
		display				: 'flex',
		flexDirection		: 'row',
	},
	upper_row_list		: {
		minWidth			: DIALOG_WIDTH / 2,
	},
	content_item		: {
		marginLeft			: '-24px'
	},
	text_item 			: {
		minWidth			: '150px',
		marginLeft			: '150px'
	},
	icon				: {
		marginRight			: '0px'
	},
	dialog_actions 				: {
		[theme.breakpoints.down( 'sm' )] : {
			marginBottom	: 40,
			marginRight		: 40
		}
	}
} )

class WhatsNewDialog extends React.Component {
	render() {
		if ( !this.props.isOpen ) return ( <div /> )

		return (
			<Dialog
				open={ this.props.isOpen }
				onClose={ this.props.closeDialog }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle id="dialog-title">{ 'What\'s New' }</DialogTitle>
				<DialogContent>
					<div className={ this.props.classes.content }>
						<div className={ this.props.classes.content_upper_row }>
							<List dense>
								<ListItem className={ this.props.classes.content_item }>
									<ListItemText primary="Version" secondary={ config.appVersion } />
									<ListItemText className={ this.props.classes.text_item } primary="Date Released" secondary={ DATE_RELEASED } />
								</ListItem>
							</List>
						</div>
						<List subheader={ <ListSubheader component="div">Features</ListSubheader> } dense>
							{
								NEW_FEATURES.map( ( bullet ) => {
									return (
										<ListItem key={ bullet } className={ this.props.classes.content_item }>
											<ListItemIcon className={ this.props.classes.icon }><BulletPointIcon /></ListItemIcon>
											<ListItemText primary={ bullet } />
										</ListItem>
									)
								} )
							}
						</List>
					</div>
				</DialogContent>
				<DialogActions className={ this.props.classes.dialog_actions }>
					<Button id="close-dialog" onClick={ this.props.closeDialog } color="primary" variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


WhatsNewDialog.propTypes = {
	isOpen			: PropTypes.bool.isRequired,
	closeDialog		: PropTypes.func.isRequired,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}

export default withStyles( styles )( WhatsNewDialog )
