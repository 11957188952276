import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import _cloneDeep from 'lodash/cloneDeep'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Edit from '@material-ui/icons/Edit'
import Switch from '@material-ui/core/Switch'

import API from '../../lib/api'
import Analytics from '../../lib/analytics'

import EditionSelector from '../common/edition_selector'
import TwitterSettings from '../settings/twitter_settings'
import EditionDialog from '../compose/edition_dialog'
import helpers from '../../lib/helpers'
import CategoryLayout from '../common/category_layout'


const styles = ( theme ) => ( {
	outer_div		: {
		[theme.breakpoints.up( 'sm' )] : {
			marginTop: 10
		}
	},
	paper			: {
		padding			: 10,
		marginLeft		: 8,
		marginRight		: 8,
		marginBottom	: 8
	},
	section_spacing	: {
		marginTop		: 20,
		[theme.breakpoints.down( 'sm' )] : {
			marginTop	: 5
		}
	},
	default_label	: {
		marginTop		: 20,
	},
	mobile_div			: {
		display		: 'flex',
		alignItems	: 'center',
		width		: '100%',
		marginTop	: 10,
		marginBottom : 10
	}
} )


const LANGUAGE = {
	'en' : 'English',
	'es' : 'Spanish',
	'pt' : 'Portuguese'
}


const EDITIONS = {
	17 : 'Argentina',
	16 : 'Australia',
	26 : 'Brasil',
	18 : 'Chile',
	19 : 'Colombia',
	25 : 'Global',
	20 : 'Mexico',
	30 : 'Philippines',
	22 : 'US (Spanish)',
	21 : 'Venezuela',
	0 :  'Domestic'
}


class Settings extends React.Component {
	constructor( props ) {
		super( props )

		this.state = { categories_search: this.initialCategoriesSearchState, language_edition_dialog: { open: false } }

		this.updateLanguage = this.updateLanguage.bind( this )
		this.updateEditions = this.updateEditions.bind( this )
		this.updateTwitterSettings = this.updateTwitterSettings.bind( this )
		this.toggleDeveloperMode = this.toggleDeveloperMode.bind( this )
		this.openDefaultLanguageEditions = this.openDefaultLanguageEditions.bind( this )
		this.closeDefaultLanguageEditions = this.closeDefaultLanguageEditions.bind( this )
		this.updateCurrentUser = this.updateCurrentUser.bind( this )
	}


	get initialCategoriesSearchState() {
		return { current_search_term: '', type: 'any', searching: false, results: { categories: [], error: null } }
	}


	searchCategories( searchTerm, categoryType ) {
		if ( searchTerm === '' ) return

		let newState = _cloneDeep( this.state )
		newState.categories_search.searching = true

		let searchGuruCategoryType
		if ( categoryType !== 'any' ) searchGuruCategoryType = categoryType

		this.setState( newState, () => {
			API.Categories.searchCategories( this.props.currentUser, searchTerm, searchGuruCategoryType )
				.then( ( searchResults ) => {
					if ( searchResults.searchTerm !== this.state.categories_search.current_search_term ) return

					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: searchResults.models, error: null }

					this.setState( newState )
				} )
				.catch( ( err ) => {
					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: [], error: err }

					this.setState( newState )
				} )
		} )
	}


	updateLanguage( newLanguage ) {
		Analytics.recordUserActivity()

		let newUser = _cloneDeep( this.props.currentUser )
		newUser.shortstop.default_language = newLanguage

		this.props.updateCurrentUser( newUser )
	}


	updateEditions( newEditions ) {
		Analytics.recordUserActivity()

		let newUser = _cloneDeep( this.props.currentUser )
		newUser.shortstop.default_editions = newEditions

		this.props.updateCurrentUser( newUser )
	}


	updateTwitterSettings( newSettings ) {
		Analytics.recordUserActivity()

		let newUser = _cloneDeep( this.props.currentUser )
		newUser.shortstop.social_tokens.twitter = newSettings

		this.props.updateCurrentUser( newUser )
	}

	toggleDeveloperMode() {
		Analytics.recordUserActivity()

		let newUser = _cloneDeep( this.props.currentUser )
		newUser.shortstop.dev_mode = !this.props.currentUser.shortstop.dev_mode

		this.props.updateCurrentUser( newUser )
	}


	openDefaultLanguageEditions() {
		let newState = _cloneDeep( this.state )
		newState.language_edition_dialog.open = true

		this.setState( newState )
	}


	closeDefaultLanguageEditions( language, editions, save ) {
		let newState = _cloneDeep( this.state )
		newState.language_edition_dialog.open = false

		if ( save ) {
			this.setState( newState, () => {
				let newUser = _cloneDeep( this.props.currentUser )
				newUser.shortstop.default_editions = editions
				newUser.shortstop.default_language = language

				this.props.updateCurrentUser( newUser )
			} )
		}
		else {
			this.setState( newState )
		}
	}

	updateCurrentUser( newUser ) {
		this.props.updateCurrentUser( newUser )
	}


	renderDefaultLanguageEditions() {
		if ( helpers.isMobile() ) {
			let editionsText = EDITIONS[0]

			if ( this.props.currentUser.shortstop.default_editions.length > 0 ) {
				editionsText = this.props.currentUser.shortstop.default_editions.map( ( edition, index ) => {
					return ` ${ EDITIONS[ edition ] }`
				} )
			}

			return (
				<div className={ this.props.classes.mobile_div } >
					<Typography variant="body1" color="textSecondary" wrap="true" className={ this.props.classes.mobile_div }>
						<span style={ { width: '100%', display : 'flex', flexDirection: 'column' } }>
							<span>
								<b>Language:</b>
								{ ` ${ LANGUAGE[ this.props.currentUser.shortstop.default_language ] }` }
							</span>
							<span>
								<b>Editions:</b>
								{ ` ${ editionsText }` }
							</span>
						</span>

						<span className={ this.props.classes.edit_div }>
							<Fab color="secondary" aria-label="Edit" className={ this.props.classes.edit_button } size="small" onClick={ this.openDefaultLanguageEditions }>
								<Edit />
							</Fab>
						</span>
					</Typography>
				</div>
			)
		}
		else {
			return (
				<EditionSelector
					currentLanguage={ this.props.currentUser.shortstop.default_language }
					currentEditions={ this.props.currentUser.shortstop.default_editions }
					updateLanguage={ this.updateLanguage }
					updateEditions={ this.updateEditions }
				/>
			)
		}
	}


	render() {
		let languageEdition = ( <div /> )
		if ( this.state.language_edition_dialog.open ) {
			languageEdition = (
				<EditionDialog
					currentLanguage={ this.props.currentUser.shortstop.default_language }
					currentEditions={ this.props.currentUser.shortstop.default_editions }
					isOpen={ this.state.language_edition_dialog.open }
					closeDialog={ this.closeDefaultLanguageEditions }
				/>
			)
		}

		return (
			<div className={ this.props.classes.outer_div }>
				<Paper className={ this.props.classes.paper } elevation={ 1 }>
					<Typography variant="subtitle1" color="inherit" noWrap>
						DEFAULT CATEGORIES
					</Typography>

					<div style={ { marginTop: 10, marginBottom: 10 } }>
						<CategoryLayout
							currentUser={ this.props.currentUser }
							post={ {} }
							updatePostState={ () => {} }
							updateCurrentUser={ this.updateCurrentUser }
						/>
					</div>
				</Paper>

				<Paper id="defaultLanguageEditionsContainer" className={ classNames( this.props.classes.paper, this.props.classes.section_spacing ) } elevation={ 1 }>
					<Typography variant="subtitle1" color="inherit" noWrap>
						DEFAULT LANGUAGE / EDITIONS
					</Typography>

					{ this.renderDefaultLanguageEditions() }
				</Paper>

				<Paper id="twitterSettingsContainer" className={ classNames( this.props.classes.paper, this.props.classes.section_spacing ) } elevation={ 1 }>
					<Typography variant="subtitle1" color="inherit" noWrap>
						TWITTER SETTINGS
					</Typography>

					<TwitterSettings
						currentUser={ this.props.currentUser }
						twitterSettings={ this.props.currentUser.shortstop.social_tokens.twitter }
						updateTwitterSettings={ this.updateTwitterSettings }
						showErrorDialog={ this.props.showErrorDialog } />
				</Paper>
				{ languageEdition }

				<Paper id="devModeContainer" className={ classNames( this.props.classes.paper, this.props.classes.section_spacing ) } elevation={ 1 }>
					<Typography variant="subtitle1" color="inherit" display="inline">
						DEVELOPER MODE
					</Typography>

					<Switch
						onChange={ this.toggleDeveloperMode }
						checked={ this.props.currentUser.shortstop.dev_mode }
						color="primary"
					/>

				</Paper>
			</div>
		)
	}
}


Settings.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	updateCurrentUser	: PropTypes.func.isRequired,
	showErrorDialog		: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,
}


export default withStyles( styles )( Settings )
