import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import ReporterEvent from '../../models/reporter_event'

import BaseAPI from './_api'


export default class ReporterEventsAPI {
	static searchReporterEvents( currentUser, limit, offset ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/reporter-events`

			let queryParams = { limit: limit, offset: offset, user_id: currentUser._id.toString() }

			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Search ${ ReporterEvent.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new ReporterEvent( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static getReporterEvent( currentUser, reporterEventId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/reporter-events/${ reporterEventId }`

			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Get ${ ReporterEvent.friendlyName }` )

					resolve( new ReporterEvent( res.body ) )
				} )
		} )
	}


	static createReporterEvent( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/reporter-events/`

			BaseAPI.setupRequest( currentUser, request.post( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Post ${ ReporterEvent.friendlyName }` )

					resolve( new ReporterEvent( res.body ) )
				} )
		} )
	}
}
