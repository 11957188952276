import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'


class AppNotification extends React.Component {
	renderSnackbar( message ) {
		return (
			<Snackbar
				anchorOrigin={ {
					vertical	: 'bottom',
					horizontal	: 'center',
				} }
				open={ this.props.open }
				autoHideDuration={ 3000 }
				onClose={ this.props.notificationDismissed }
				message={ <span>{ message }</span> }
				TransitionComponent={ Slide }
			/>
		)
	}


	render() {
		return this.renderSnackbar( this.props.notification.message )
	}
}


AppNotification.propTypes = {
	notification			: PropTypes.object.isRequired,
	open					: PropTypes.bool.isRequired,
	notificationDismissed	: PropTypes.func.isRequired
}


export default AppNotification
