import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import LocalStorage from '../../lib/local_storage'

import { withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Tooltip from '@material-ui/core/Tooltip'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import VideoCamIcon from '@material-ui/icons/Videocam'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import EventIcon from '@material-ui/icons/Event'

const styles = ( theme ) => ( {
	outer_div		: {
		width			: '100%',
		[theme.breakpoints.down( 'sm' )] : {
			height: '100%',
			marginTop: 0
		}
	},
	paper 			: {
		width			: '100%',
		[theme.breakpoints.down( 'sm' )] : {
			height: '100%'
		}
	},
	tableRoot			: {
		width			: '100%',
		maxWidth		: '100%'
	},
	mediaEventIconCell		: {
		width: '5%',
		marginLeft : 0,
		paddingRight: 0
	},
	mediaEventIcon			: {
		marginLeft: 0,
		paddingRight: 2
	},
	bodyCell		: {
		width: '40%',
		paddingRight: 10
	},
	categoryCell	: {
		width: '25%'
	},
	dateCell		: {
		width: '15%'
	},
	editDeleteCell	: {
		width: '20%',
	},

} )


const ICON_FONT_SIZE = 'small'
const TABLE_SIZE = 'small'
const MAX_POST_TEXT_WORDS = 13


class Drafts extends React.Component {
	constructor( props ) {
		super( props )
		this.state = { recentDrafts: [] }

		this._storage = new LocalStorage( window )

		this.handleEditDraft = this.handleEditDraft.bind( this )
		this.handleDeleteDraft = this.handleDeleteDraft.bind( this )
	}

	get storage() { return this._storage }


	componentWillMount() {
		this.setState( { recentDrafts: this.storage.recentDrafts } )
	}


	formatDate( unix ) {
		return moment( unix ).fromNow()
	}


	renderContentIcon( draft ) {
		const color = 'action'
		let icon = ( <SpeakerNotesIcon color={ color } fontSize={ ICON_FONT_SIZE } /> )
		const postType = draft.shortstop.media_type

		if ( postType === 'photo' ) {
			icon = ( <PhotoCameraIcon color={ color } fontSize={ ICON_FONT_SIZE } /> )
		}
		else if ( postType === 'video' ) {
			icon = ( <VideoCamIcon color={ color } fontSize={ ICON_FONT_SIZE } /> )
		}

		return icon
	}

	renderSecondaryContentIcon( draft ) {
		const color = 'action'
		let icon = ( <div /> )
		const hasEvent = draft.shortstop.event !== undefined

		if ( hasEvent ) {
			icon = ( <EventIcon color={ color } fontSize={ ICON_FONT_SIZE } /> )
		}

		return icon
	}

	renderCondensedArrayOfStrings( arr, maxElements ) {
		let content = <div />
		if ( arr.length === 0 ) {
			content = arr[0]
		}
		else if ( arr.length <= maxElements ) {
			content = arr.join( ', ' )
		}
		else if ( arr.length > maxElements ) {
			const notDisplayedCount = arr.length - maxElements
			content = (
				<div>
					{ arr.slice( 0, maxElements ).join( ', ' ) }
					<Tooltip title={ arr.slice( maxElements ).join( ', ' ) } enterDelay={ 500 } leaveDelay={ 200 } placement="bottom">
						<Typography variant="caption" color="textSecondary">
							&nbsp;and { notDisplayedCount } more
						</Typography>
					</Tooltip>
				</div>

			)
		}

		return content
	}

	renderCondensedStringOfText( text, maxWords ) {
		let renderFunction = toRender => {
			return (
				<Typography style={ { whiteSpace: 'pre-wrap' } } variant="inherit" color="textPrimary" >
					{ toRender }
				</Typography>

			)
		}

		const arrayOfLines = text.split( '\n' ).map( line => line.split( ' ' ) )

		if ( arrayOfLines.flat().length < maxWords ) {
			return renderFunction( text )
		}

		let count = 0
		let content = ''

		arrayOfLines.some( line => {
			line.some( word => {
				count = count + 1
				if ( count < maxWords ) {
					content = `${ content } ${ word }`
				}

				return count === maxWords
			} )

			if ( count < maxWords ) {
				content = `${ content }\n`
			}

			return count === maxWords
		} )

		return renderFunction( `${ content }...` )
	}


	handleEditDraft( draftId ) {
		this.props.routeProps.history.push( `/compose?draftId=${ draftId }` )
	}


	handleDeleteDraft( draftId ) {
		return new Promise( ( resolve, reject ) => {
			try {
				let recentDrafts = this.storage.recentDrafts
				const foundIndex = recentDrafts.findIndex( draft => draft.created === draftId )
				recentDrafts.splice( foundIndex, 1 )
				this.storage.recentDrafts = recentDrafts
				this.setState( { recentDrafts }, () => {
					this.props.showNotification( 'Draft deleted' )
					resolve()
				} )
			}
			catch ( e ) {
				reject( e )
			}
		} )
	}


	render() {
		let recentDrafts = this.state.recentDrafts
		return (
			<div className={ this.props.classes.outer_div }>
				<Paper elevation={ 2 } className={ this.props.classes.paper }>
					<Table className={ this.props.classes.tableRoot } size={ TABLE_SIZE }>
						<TableHead>
							<TableRow>
								<TableCell className={ this.props.classes.mediaEventIconCell } />
								<TableCell className={ this.props.classes.bodyCell }>Post Text</TableCell>
								<TableCell className={ this.props.classes.categoryCell }>Categories</TableCell>
								<TableCell className={ this.props.classes.dateCell }>Created</TableCell>
								<TableCell className={ this.props.classes.editDeleteCell } />
							</TableRow>
						</TableHead>
						<TableBody>
							{ recentDrafts.sort( ( a, b ) => { return b.created - a.created } ).map( ( draft ) => {
								return (
									<TableRow key={ draft.created }>
										<TableCell className={ this.props.classes.mediaEventIconCell } align="left">
											<div className={ this.props.classes.mediaEventIcon }>{ this.renderContentIcon( draft ) }</div>
											<div className={ this.props.classes.mediaEventIcon }>{ this.renderSecondaryContentIcon( draft ) }</div>
										</TableCell>

										<TableCell className={ this.props.classes.bodyCell }>
											{ this.renderCondensedStringOfText( draft.shortstop.body_text, MAX_POST_TEXT_WORDS ) }
										</TableCell>

										<TableCell className={ this.props.classes.categoryCell }>
											{ this.renderCondensedArrayOfStrings( draft.shortstop.categories.map( category => category.name ), 2 ) }
										</TableCell>

										<TableCell className={ this.props.classes.dateCell }>{ this.formatDate( draft.created ) }</TableCell>

										<TableCell className={ this.props.classes.editDeleteCell } align="right">
											<Tooltip title="Edit draft" enterDelay={ 500 } leaveDelay={ 200 }>
												<IconButton id="edit" onClick={ () => { this.handleEditDraft( draft.created ) } } >
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete draft permanently" enterDelay={ 500 } leaveDelay={ 200 }>
												<IconButton id="delete" onClick={ () => { this.handleDeleteDraft( draft.created ) } } >
													<DeleteForeverIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							} ) }
						</TableBody>
					</Table>
				</Paper>
			</div>
		)
	}
}


Drafts.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	currentUser			: PropTypes.object.isRequired,

	showNotification 	: PropTypes.func.isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	showErrorDialog		: PropTypes.func.isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	showProgressDialog	: PropTypes.func.isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	closeProgressDialog	: PropTypes.func.isRequired,

	// test hooks
	// api					: PropTypes.object,
	// mounted				: PropTypes.func,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// provided by react router
	routeProps			: PropTypes.object.isRequired,
}

export default withStyles( styles )( Drafts )
