import API from './api'

import config from './config'
import Analytics from './analytics'
import LocalStorage from './local_storage'
import moment from 'moment'
import helpers from './helpers'


class LoginManager {
	constructor( window ) {
		this._window = window
		this._storage = new LocalStorage( window )

		this._oneId = null
		this._eventHandlers = { authenticated: [], unauthenticated: [], unauthorized: [], expired: [], error: [] }
	}


	get oneId() { return this._oneId }
	set oneId( oneId ) { this._oneId = oneId }

	get storage() { return this._storage }
	get window() { return this._window }
	get eventHandlers() { return this._eventHandlers }


	get oneIdConfig() {
		let responderPage = `${ config.clientHost }/one_id_responder_v4.html`
		if ( config.environment === 'local' ) {
			responderPage = 'https://mexui.dev:9283/one_id_responder_v4.html'
		}

		const oneIdConfig = {
			debug			: false,
			clientId		: config.oidClientId,
			responderPage	: responderPage,
			cssOverride		: 'https://cdn.secure.espn.go.com/combiner/c?css=disneyid/core.css'
		}

		return oneIdConfig
	}


	async initialize() {
		this.oneId = window.OneID.get( this.oneIdConfig )

		this.oneId.on( 'login', ( guest ) => { this.loginWithMediaExchange( guest ) } )

		let data = await this.oneId.init()
		let msSinceLastAction = moment.duration( moment().diff( this.storage.windowClosedTimeStamp ) ).asMilliseconds()
		if ( data.loggedIn ) {
			if ( !helpers.isMobile() ) {
				if ( msSinceLastAction < config.idleTimeoutInMS ) {
					return this.loginWithMediaExchange( data.guest )
				}
				else {
					return this.logout()
				}
			}
			else {
				return this.loginWithMediaExchange( data.guest )
			}
		}
		else {
			if ( data.guest ) {
				return this.logout()
			}

			return this.login()
		}
	}


	login() {
		Analytics.clearUserActivity()
		this.oneId.launchLogin()
	}


	async logout() {
		this.storage.lastUserId = this.storage.currentUser._id
		this.storage.currentUser = null

		Analytics.clearUserActivity()
		await this.oneId.logout()

		for ( let eventHandler of this.eventHandlers.unauthenticated ) {
			eventHandler()
		}
	}


	getGuestInfo() {
		return new Promise( ( resolve, reject ) => {
			this.oneId.getGuest( true, {} ).then( ( res ) => { resolve( res ) } ).catch( ( e ) => { reject( e ) } )
		} )
	}


	async loginWithMediaExchange( guest ) {
		try {
			const guestInfo = await this.getGuestInfo()

			let user = this.storage.currentUser
			if ( user === null || user.contact.email !== guestInfo.profile.email ) {
				user = await API.Auth.loginWithOneId( guestInfo.profile.email, guest.token.swid, guest.token.access_token )

				this.storage.currentUser = user

				if ( this.storage.lastUserId !== user._id ) {
					this.storage.recentEvents = []
				}

				this.storage.windowClosedTimeStamp = 0 // need to reset local storage that is dependent on closing the window
			}

			Analytics.recordUserActivity()
			this.setTimer()

			for ( let eventHandler of this.eventHandlers.authenticated ) {
				eventHandler( user )
			}
		}
		catch ( e ) {
			await this.oneId.logout()

			if ( e.statusCode === 401 ) {
				for ( let eventHandler of this.eventHandlers.unauthorized ) {
					eventHandler( guest.profile.email )
				}
			}
			else {
				for ( let eventHandler of this.eventHandlers.error ) {
					eventHandler( e )
				}
			}
		}
	}


	on( eventName, handler ) {
		this.eventHandlers[ eventName ].push( handler )
	}


	async checkAndHandleTimeout() {
		let sessionInfo = Analytics.sessionInfo

		if ( sessionInfo.isExpired ) {
			// only show the session timeout dialog if it's been under an hour

			let showDialog = false
			if ( sessionInfo.minutesSinceLastAction < 60 ) {
				showDialog = true
			}

			this.storage.currentUser = null
			await this.oneId.logout()

			for ( let eventHandler of this.eventHandlers.expired ) {
				eventHandler( showDialog )
			}
		}
		else {
			this.setTimer()
		}
	}


	setTimer() {
		let checkTimeInMS = 1000 * 60 // every 60 seconds check to see if we've gone idle
		this.window.setTimeout( this.checkAndHandleTimeout.bind( this ), checkTimeInMS )
	}
}


export default LoginManager
