import React from 'react'
import PropTypes from 'prop-types'
import _concat from 'lodash/concat'
import _cloneDeep from 'lodash/cloneDeep'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Chip from '@material-ui/core/Chip'
import DialogActions from '@material-ui/core/DialogActions'

import PostPreview from '../common/post_preview'

import Timeline from '../timeline/timeline_layout'
import API from '../../lib/api'
import helpers from '../../lib/helpers'

const NUM_POSTS_PER_FETCH = 25

const styles = ( theme ) => ( {
	empty_container		: {
		marginTop			: '7%',
		textAlign			: 'center',
	},
	empty_icon			: {
		fontSize			: 60,
		paddingBottom		: 15,
		fontFamily			: 'ESPNIcons',
	},
	empty_caption		: {
		fontSize			: 15,
	},
	post_container	: {
		display			: 'flex',
		flexWrap		: 'wrap',
		marginTop		: 10,
		[theme.breakpoints.down( 'sm' )] : {
			marginTop 	: 0
		}
	},
	post			: {
		marginRight		: 20,
		marginBottom	: 20,
		[theme.breakpoints.down( 'sm' )] : {
			marginRight : 8,
			marginBottom: 8,
			marginLeft	: 8,
			width		: '100%'
		}
	},
} )

class SmileUploads extends React.Component {
	constructor( props ) {
		super( props )

		this.API = API
		if ( props.api ) this.API = props.api

		this.state = this.initialState
		this.handleGroupDialog = this.handleGroupDialog.bind( this )
		this.editPost = this.editPost.bind( this )
	}

	async componentDidMount() {
		await this.fetchPosts( this.state.current_page )
		if ( this.props.mounted ) this.props.mounted()
	}

	get initialState() {
		return {
			timeline: { data: [], total: 0 },
			current_page: 0,
			showGroupDialog: false,
			groupedPosts: [],
			selectedGroupId: null
		}
	}

	reloadTimeline() {
		this.setState( this.initialState, async () => {
			await this.fetchPosts( this.state.current_page )
		} )
	}

	async fetchPosts( pageNumber ) {
		try {
			this.props.showProgressDialog( 'Timeline', 'Fetching smile uploads' )
			let posts = await this.API.Media.searchMedia( this.props.currentUser, NUM_POSTS_PER_FETCH, NUM_POSTS_PER_FETCH * pageNumber, [], true )
			this.props.closeProgressDialog()

			const filteredData = posts.data.filter( post => helpers.doesExist( post.smile_content ) && post.smile_content === true )

			let clonedTimelineData = _cloneDeep( this.state.timeline.data )
			let newTimelineData = _concat( clonedTimelineData, filteredData )

			this.setState( { timeline: { data: newTimelineData, total: posts.total }, current_page: pageNumber } )
		}
		catch ( e ) {
			this.props.closeProgressDialog()
			this.props.showErrorDialog( 'Timeline', e )
		}
	}
	
	editPost( post ) {
		this.props.routeProps.history.push( `/edit/${ post._id }` )
	}

	handleGroupDialog( value, groupId = null ) {
		let newState = _cloneDeep( this.state )

		newState.showGroupDialog = value
		newState.selectedGroupId = groupId

		if ( !value ) {
			newState.selectedGroupId = null
			newState.groupedPosts = []
		}

		this.setState( newState, () => {
			if ( newState.showGroupDialog && helpers.doesExist( groupId ) ) {
				this.fetchGroupedPosts( groupId )
			}
		} )
	}

	async fetchGroupedPosts( groupId ) {
		try {
			this.props.showProgressDialog( 'Group', 'Fetching grouped posts' )
			let posts = await this.API.Media.searchMedia(
				this.props.currentUser,
				NUM_POSTS_PER_FETCH,
				NUM_POSTS_PER_FETCH * 0,
				[],
				true,
				groupId
			)
			this.props.closeProgressDialog()
			this.setState( { groupedPosts: posts.data } )
		}
		catch ( e ) {
			this.props.closeProgressDialog()
			this.props.showErrorDialog( 'Group', e )
		}
	}

	renderEmptyTimeline() {
		return (
			<div className={ this.props.classes.empty_container }>
				<div className={ this.props.classes.empty_icon }>&#57456;</div>

				<Typography variant="subtitle1" color="inherit" noWrap>
					You haven&#39;t upload any asset yet!
				</Typography>

				<br />

				<Typography variant="caption" color="inherit" noWrap className={ this.props.classes.empty_caption }>
					Go to the <Link to="/compose" style={ { textDecoration: 'none' } }>Create</Link> screen to get started
				</Typography>
			</div>
		)
	}


	render() {
		if ( this.state.timeline.total === 0 ) return this.renderEmptyTimeline()

		let groupDialog = ( <div /> )
		if ( this.state.showGroupDialog ) {
			groupDialog = (
				<Dialog
					fullWidth
					maxWidth="lg"
					open={ this.state.showGroupDialog }
					onClose={ () => this.handleGroupDialog( false ) }
					aria-labelledby="max-width-dialog-title"
				>
					<DialogTitle id="max-width-dialog-title">
						<Chip label={ `Group: ${ this.state.selectedGroupId }` } variant="outlined" />
					</DialogTitle>
					<DialogContent>
						<div className={ this.props.classes.post_container }>
							{
								this.state.groupedPosts.map( ( post ) => {
									return (
										<div key={ post._id } className={ this.props.classes.post }>
											<PostPreview
												currentUser={ this.props.currentUser }
												post={ post }
												displayMenu={ !post.smile_content }
												hideShowGroupLink
												small
											/>
										</div>
									)
								} )
							}
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={ () => this.handleGroupDialog( false ) } color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			)
		}

		return (
			<div>
				<Timeline
					currentUser={ this.props.currentUser }
					timeline={ this.state.timeline }
					showGroupDialog={ this.handleGroupDialog }
					editPost={ this.editPost }
				/>
				{ groupDialog }
			</div>
		)
	}
}

SmileUploads.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	showErrorDialog		: PropTypes.func.isRequired,
	showProgressDialog	: PropTypes.func.isRequired,
	closeProgressDialog	: PropTypes.func.isRequired,

	// test hooks
	api					: PropTypes.object,
	mounted				: PropTypes.func,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,
	routeProps		: PropTypes.object.isRequired,
}

export default withStyles( styles )( SmileUploads )
