import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Switch from '@material-ui/core/Switch'
import TwitterIcon from '@material-ui/icons/PeopleOutline'

import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import helpers from '../../lib/helpers'

class CrossPublishing extends React.Component {
	constructor( props ) {
		super( props )

		// create the local state object from the media model
		this.state = {
			crossPublishingSettings: {
				twitter		: _get( _cloneDeep( props.post ), 'shortstop.twitter_sharing.cross_publish', false )
			}
		}

		this.createPostFromState = this.createPostFromState.bind( this )
		this.updateCrossPublishingSetting = this.updateCrossPublishingSetting.bind( this )
		this.renderSocialToggle = this.renderSocialToggle.bind( this )
	}

	componentWillReceiveProps( nextProps ) {
		this.setState( {
			crossPublishingSettings: {
				twitter: _get( _cloneDeep( nextProps.post ), 'shortstop.twitter_sharing.cross_publish', false )
			}
		} )
	}

	createPostFromState( componentState ) {
		// do the reverse of above
		let clonedPost = _cloneDeep( this.props.post )

		clonedPost.shortstop.twitter_sharing.cross_publish = componentState.crossPublishingSettings.twitter

		this.props.updatePostState( clonedPost )
	}


	updateCrossPublishingSetting( network ) {
		let newCrossPublishingSettings = _cloneDeep( this.state.crossPublishingSettings )

		if ( network === 'twitter' ) {
			if ( helpers.doesExist( this.props.currentUser.shortstop.social_tokens.twitter ) ) {
				newCrossPublishingSettings[ network ] = !newCrossPublishingSettings[ network ]
			}
		}


		this.setState( { crossPublishingSettings: newCrossPublishingSettings } )

		this.createPostFromState( { crossPublishingSettings: newCrossPublishingSettings } )
	}


	renderSocialToggle( primaryText, tokenProperty ) {
		let secondaryText = 'Account association required to publish'
		let checked = false
		let checkedDisabled = true

		if ( helpers.doesExist( this.props.currentUser.shortstop.social_tokens[ tokenProperty ] ) ) {
			secondaryText = this.props.currentUser.shortstop.social_tokens[ tokenProperty ].screen_name
			checkedDisabled = false
			checked = this.state.crossPublishingSettings[ tokenProperty ]
		}

		return (
			<div className={ tokenProperty }>
				<ListItem>
					<ListItemIcon>
						<TwitterIcon />
					</ListItemIcon>
					<ListItemText primary={ primaryText } secondary={ secondaryText } />
					<ListItemSecondaryAction>
						<Switch
							checked={ checked }
							onChange={ () => { this.updateCrossPublishingSetting( tokenProperty ) } }
							disabled={ checkedDisabled }
							color="primary"
						/>
					</ListItemSecondaryAction>
				</ListItem>
			</div>
		)
	}

	render() {
		let twitter = this.renderSocialToggle( 'Twitter', 'twitter' )

		return (
			<div>
				<List>
					{ twitter }
				</List>
			</div>
		)
	}
}

CrossPublishing.propTypes = {
	currentUser			: PropTypes.object.isRequired,

	// component specific
	post				: PropTypes.object.isRequired,
	updatePostState		: PropTypes.func.isRequired,
}


export default ( CrossPublishing )
