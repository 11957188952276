import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import Edit from '@material-ui/icons/Edit'

import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline'

import helpers from '../../lib/helpers'

import PostContent from './post_content'
import FileUpload from '../common/file_upload'
import EventSearch from '../common/event_search'
import EditionDialog from './edition_dialog'
import CrossPublishing from './cross_publishing'
import CategoryLayout from '../common/category_layout'


const styles = ( theme ) => ( {
	event_logo			: {
		width				: 30,
		height				: 30,
	},
	event_label			: {
		marginLeft			: 5,
	},
	start_time_container: {
		display				: 'flex',
		flexDirection		: 'column',
		marginLeft			: 'auto',
		marginRight			: 10,
	},
	event_start_time	: {
		marginLeft			: 40,
	},
	typography			: {
		[theme.breakpoints.up( 'sm' )] : {
			marginTop: 30,
			marginBottom: 10
		},
		marginBottom: 15
	},
	mobile_div			: {
		display		: 'flex',
		alignItems	: 'center',
		width		: '100%'
	},
	edit_div			: {
		display		: 'flex',
		justifyContent: 'flex-end'
	},
	edit_button			: {
		boxShadow		: 'none'
	}
} )


const LANGUAGE = {
	'en' : 'English',
	'es' : 'Spanish',
	'pt' : 'Portuguese'
}


const EDITIONS = {
	17 : 'Argentina',
	16 : 'Australia',
	26 : 'Brasil',
	18 : 'Chile',
	19 : 'Colombia',
	25 : 'Global',
	20 : 'Mexico',
	30 : 'Philippines',
	22 : 'US (Spanish)',
	21 : 'Venezuela',
	0 :  'Domestic'
}


class ComposeLayout extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			categories_search		: { current_search_term: '', type: 'any', searching: false, results: { categories: [], error: null } },
			event_search_dialog		: { open: false },
			file_upload				: { uploading: false },
			language_edition_dialog : { open: false }
		}

		this.updatePostState = this.updatePostState.bind( this )

		this.openAssociateEvent = this.openAssociateEvent.bind( this )
		this.closeAssociateEvent = this.closeAssociateEvent.bind( this )
		this.removeEventAssociation = this.removeEventAssociation.bind( this )

		this.startUpload = this.startUpload.bind( this )
		this.finishUpload = this.finishUpload.bind( this )
		this.removeAttachment = this.removeAttachment.bind( this )

		this.openAssociateLanguageEditions = this.openAssociateLanguageEditions.bind( this )
		this.closeAssociateLanguageEditions = this.closeAssociateLanguageEditions.bind( this )
		this.toggleSmileContent = this.toggleSmileContent.bind( this )
	}


	updatePostState( newPost, cb = null ) {
		this.props.updatePostState( newPost, cb )
	}


	openAssociateEvent() {
		let newState = _cloneDeep( this.state )
		newState.event_search_dialog.open = true

		this.setState( newState )
	}


	openAssociateLanguageEditions() {
		let newState = _cloneDeep( this.state )
		newState.language_edition_dialog.open = true

		this.setState( newState )
	}


	closeAssociateEvent( event, leagueCategory ) {
		let newState = _cloneDeep( this.state )
		newState.event_search_dialog.open = false

		this.setState( newState, () => {
			if ( event === null || leagueCategory === null ) return

			let newPost = _cloneDeep( this.props.post )

			newPost.shortstop.event = event

			let existingLeagueCategory = newPost.shortstop.categories.find( ( c ) => { return c.now_id === leagueCategory.now_id } )
			if ( !existingLeagueCategory ) newPost.shortstop.categories.push( leagueCategory )

			this.props.updatePostState( newPost )
		} )
	}


	removeEventAssociation( event ) {
		let newPost = _cloneDeep( this.props.post )
		newPost.shortstop.event = undefined

		this.props.updatePostState( newPost )
	}


	startUpload() {
		return new Promise( ( resolve, reject ) => {
			let newState = _cloneDeep( this.state )
			newState.file_upload.uploading = true

			this.props.updateUploadState( true )

			this.setState( newState, () => {
				resolve()
			} )
		} )
	}


	finishUpload( attachments ) {
		return new Promise( ( resolve, reject ) => {
			let newState = _cloneDeep( this.state )
			newState.file_upload.uploading = false

			this.props.updateUploadState( false )

			this.setState( newState, () => {
				let newPost = _cloneDeep( this.props.post )

				if ( this.props.post.smile_content ) {
					newPost.attachments = newPost.attachments.concat( attachments )
				}
				else {
					newPost.attachments = attachments
				}

				if ( newPost.attachments.length === 1 ) {
					newPost.shortstop.media_type = 'photo'
				}
				else if ( newPost.attachments.length === 2 ) {
					newPost.shortstop.media_type = 'video'
				}

				this.props.updatePostState( newPost )

				resolve()
			} )
		} )
	}


	removeAttachment( index = null ) {
		let newPost = _cloneDeep( this.props.post )

		if ( index === null ) {
			newPost.attachments = []
		}
		else {
			newPost.attachments.splice( index, 1 )
		}

		if ( newPost.attachments.length === 0 ) {
			newPost.shortstop.media_type = 'text'
		}

		this.props.updatePostState( newPost )
	}


	closeAssociateLanguageEditions( language, editions ) {
		let newState = _cloneDeep( this.state )
		newState.language_edition_dialog.open = false

		this.setState( newState, () => {
			let newPost = _cloneDeep( this.props.post )

			newPost.shortstop.language = language
			newPost.shortstop.editions = editions

			this.props.updatePostState( newPost )
		} )
	}

	toggleSmileContent() {
		let newPost = _cloneDeep( this.props.post )

		newPost.smile_content = !newPost.smile_content
		newPost.attachments = []
		newPost.shortstop.media_type = 'text'

		if ( newPost.smile_content ) {
			newPost.shortstop.twitter_sharing.cross_publish = false
			newPost.shortstop.editions = []
			delete newPost.shortstop.event
		}

		this.props.updatePostState( newPost )
	}

	renderAssociatedEvent() {
		if ( this.props.post.shortstop.event === undefined ) {
			return (
				<Button fullWidth variant="outlined" color="secondary" component="span" onClick={ this.openAssociateEvent }>
					Associate Event
				</Button>
			)
		}

		return (
			<div style={ { display: 'flex', alignItems: 'center' } }>
				<div style={ { display: 'flex', flexDirection: 'column' } }>
					<div style={ { display: 'flex', alignItems: 'center' } }>
						<img className={ this.props.classes.event_logo } alt={ this.props.post.shortstop.event.vis_team.name } src={ helpers.getSecureCDNLink( this.props.post.shortstop.event.vis_team.logo_url ) } />
						<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textPrimary" noWrap>
							{ this.props.post.shortstop.event.vis_team.name }
						</Typography>
					</div>

					<div style={ { display: 'flex', alignItems: 'center', marginTop: 10 } }>
						<img className={ this.props.classes.event_logo } alt={ this.props.post.shortstop.event.home_team.name } src={ helpers.getSecureCDNLink( this.props.post.shortstop.event.home_team.logo_url ) } />
						<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textPrimary" noWrap>
							{ this.props.post.shortstop.event.home_team.name }
						</Typography>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 20, flex: 'auto' } }>
					<Typography variant="subtitle1" color="textSecondary" style={ { marginLeft: 'auto' } }>
						{ moment( this.props.post.shortstop.event.start_time ).format( 'ddd MMM Do' ) }
					</Typography>

					<Typography variant="subtitle1" color="textSecondary" style={ { marginLeft: 'auto' } }>
						{ moment( this.props.post.shortstop.event.start_time ).format( 'h:mm A' ) }
					</Typography>
				</div>

				<div style={ { marginLeft: 'auto', flex: 'none' } }>
					<IconButton onClick={ this.removeEventAssociation }>
						<RemoveCircleIcon />
					</IconButton>
				</div>
			</div>
		)
	}


	renderAssociatedLanguageEditions() {
		let editionsText = EDITIONS[ 0 ]
		if ( this.props.post.shortstop.editions.length > 0 ) {
			editionsText = this.props.post.shortstop.editions.map( ( edition, index ) => {
				return ` ${ EDITIONS[ edition ] }`
			} )
		}

		if ( helpers.isMobile() ) {
			return (
				<div className={ this.props.classes.mobile_div } >
					<Typography variant="body1" color="textSecondary" wrap="true" className={ this.props.classes.mobile_div }>
						<span style={ { width: '100%', display : 'flex', flexDirection: 'column' } }>
							<span>
								<b>Language:</b>
								{ ` ${ LANGUAGE[ this.props.post.shortstop.language ] }` }
							</span>
							<span>
								<b>Editions:</b>
								{ ` ${ editionsText } ` }
							</span>
						</span>

						<span className={ this.props.classes.edit_div }>
							<Fab color="secondary" aria-label="Edit" className={ this.props.classes.edit_button } size="small" onClick={ this.openAssociateLanguageEditions }>
								<Edit />
							</Fab>
						</span>
					</Typography>
				</div>
			)
		}
		else {
			return (
				<div>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell style={ { border: 'none' } }>Language: { LANGUAGE[ this.props.post.shortstop.language ] }</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Editions: { editionsText }</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Button fullWidth variant="outlined" color="secondary" component="span" onClick={ this.openAssociateLanguageEditions }>
						Alter Settings
					</Button>
				</div>
			)
		}
	}

	renderEventSection() {
		if ( this.hideWhenSmileContentEnabled() ) {
			return <div />
		}

		let title = <div className={ this.props.classes.typography } />

		if ( !helpers.isMobile() ) {
			title = (
				<Typography color="inherit" className={ this.props.classes.typography }>
					EVENT
				</Typography>
			)
		}

		return (
			<>
				{ title }
				{ this.renderAssociatedEvent() }
			</>
		)
	}

	renderSocialSection() {
		if ( this.hideWhenSmileContentEnabled() ) {
			return <div />
		}

		let title = ''

		if ( !helpers.isMobile() ) {
			title = (
				<Typography color="inherit" className={ this.props.classes.typography } >
					SOCIAL
				</Typography>
			)
		}

		return (
			<>
				{ title }
				<CrossPublishing currentUser={ this.props.currentUser } post={ this.props.post } updatePostState={ this.props.updatePostState } />
			</>
		)
	}

	renderLanguageAndEditionsSection() {
		if ( this.hideWhenSmileContentEnabled() ) {
			return <div />
		}

		let title = <div className={ this.props.classes.typography } />

		if ( !helpers.isMobile() ) {
			title = (
				<Typography color="inherit" className={ this.props.classes.typography } >
					LANGUAGE & EDITIONS
				</Typography>
			)
		}

		return (
			<>
				{ title }
				{ this.renderAssociatedLanguageEditions() }
			</>
		)
	}

	renderColumn1() {
		const errorMessage = this.props.post.smile_content && this.props.post.attachments.length === 0 ? 'You must add an attachment' : null

		return (
			<div>
				<Typography color="inherit" style={ { marginBottom: 10 } }>
					{ this.props.title }
				</Typography>

				<PostContent post={ this.props.post } updatePostState={ this.props.updatePostState } />

				{
					( !helpers.isMobile() ) ? (
						<Typography color="inherit" className={ this.props.classes.typography }>
							CATEGORIES
						</Typography> ) : <div className={ this.props.classes.typography } />
				}

				<CategoryLayout
					currentUser={ this.props.currentUser }
					post={ this.props.post }
					updatePostState={ this.updatePostState }
					updateCurrentUser={ () => {} }
				/>

				{ this.renderEventSection() }

				{
					( !helpers.isMobile() ) ? (
						<Typography color="inherit" className={ this.props.classes.typography }>
							ATTACHMENT
						</Typography> ) : <div className={ this.props.classes.typography } />
				}

				<FileUpload
					currentUser={ this.props.currentUser }
					showErrorDialog={ this.props.showErrorDialog }
					post={ this.props.post }
					uploading={ this.state.file_upload.uploading }
					startUpload={ this.startUpload }
					finishUpload={ this.finishUpload }
					removeAttachment={ this.removeAttachment }
					errorMessage={ errorMessage }
					disabled={ this.props.post.smile_content && !this.props.isNew }
				/>

				{ /*
					Smile Project
					<Typography color="inherit" className={ this.props.classes.typography }>
						SMILE CONTENT
					</Typography>

					<Switch
						checked={ this.props.post.smile_content }
						onChange={ this.toggleSmileContent }
						inputProps={ { 'aria-label': 'smile content checkbox' } }
						color="primary"
						disabled={ !this.props.isNew }
					/>
				*/ }
			</div>
		)
	}


	renderColumn2() {
		return (
			<div>
				{ this.renderSocialSection() }
				{ this.renderLanguageAndEditionsSection() }
			</div>
		)
	}


	renderBoth() {
		let column1 = this.renderColumn1()
		let column2 = this.renderColumn2()

		return (
			<div>
				{ column1 }
				{ column2 }
			</div>
		)
	}

	hideWhenSmileContentEnabled() {
		return helpers.doesExist( this.props.post.smile_content ) && this.props.post.smile_content
	}

	render() {
		let eventsSearch = ( <div /> )
		if ( this.state.event_search_dialog.open && !this.hideWhenSmileContentEnabled() ) {
			eventsSearch = (
				<EventSearch
					currentUser={ this.props.currentUser }
					showErrorDialog={ this.props.showErrorDialog }
					isOpen={ this.state.event_search_dialog.open }
					closeDialog={ this.closeAssociateEvent }
					showRecentEvents
				/>
			)
		}

		let languageEdition = ( <div /> )
		if ( this.state.language_edition_dialog.open && !this.hideWhenSmileContentEnabled() ) {
			languageEdition = (
				<EditionDialog
					currentLanguage={ this.props.post.shortstop.language }
					currentEditions={ this.props.post.shortstop.editions }
					isOpen={ this.state.language_edition_dialog.open }
					closeDialog={ this.closeAssociateLanguageEditions }
				/>
			)
		}

		return (
			<div>
				{
					this.props.column === 1 ? this.renderColumn1() : this.props.column === 2 ? this.renderColumn2() : this.renderBoth()
				}
				{ eventsSearch }
				{ languageEdition }
			</div>
		)
	}
}

ComposeLayout.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	showErrorDialog		: PropTypes.func.isRequired,

	// component specific
	post				: PropTypes.object.isRequired,
	updatePostState		: PropTypes.func.isRequired,
	updateUploadState	: PropTypes.func.isRequired,
	title				: PropTypes.string.isRequired,
	column				: PropTypes.number.isRequired,
	isNew				: PropTypes.bool,

	// injected by material-ui
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( ComposeLayout )
