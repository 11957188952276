import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import Category from '../../models/category'

import BaseAPI from './_api'


export default class CategoriesAPI {
	static searchCategories( currentUser, searchTerm, categoryType ) {
		let query = { q: searchTerm }
		if ( helpers.doesExist( categoryType ) ) query.filters = categoryType

		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v2/categories`
			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.query( query )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Search ${ Category.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Category( item ) )
					} )

					resolve( { searchTerm, models } )
				} )
		} )
	}


	static getLeagueCategory( currentUser, leagueId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v2/categories/league/${ leagueId }`
			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Get League ${ Category.friendlyName }` )
					resolve( new Category( res.body ) )
				} )
		} )
	}
}
