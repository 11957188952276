import _get from 'lodash/get'

import helpers from '../lib/helpers'


export default class Media {
	static get friendlyName() { return 'Media' }
	static get modelName() { return Media.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Media.friendlyName.replace( ' ', '_' ).toLowerCase() }


	static getEditionsDisplayString( editions ) {
		let editionNames = []

		for ( let edition of editions ) {
			if ( edition === 17 ) {
				editionNames.push( 'Argentina' )
			}
			else if ( edition === 18 ) {
				editionNames.push( 'Chile' )
			}
			else if ( edition === 19 ) {
				editionNames.push( 'Colombia' )
			}
			else if ( edition === 20 ) {
				editionNames.push( 'Mexico' )
			}
			else if ( edition === 21 ) {
				editionNames.push( 'Venezuela' )
			}
			else if ( edition === 22 ) {
				editionNames.push( 'US (Spanish)' )
			}
			else if ( edition === 26 ) {
				editionNames.push( 'Brasil' )
			}
			else if ( edition === 16 ) {
				editionNames.push( 'Australia' )
			}
			else if ( edition === 25 ) {
				editionNames.push( 'Global' )
			}
			else if ( edition === 30 ) {
				editionNames.push( 'Philippines' )
			}
		}

		if ( editionNames.length === 0 ) editionNames = [ 'None' ]

		return editionNames
	}


	static getLanguageDisplayString( lang ) {
		if ( lang === 'es' ) {
			return 'Spanish'
		}
		else if ( lang === 'pt' ) {
			return 'Portuguese'
		}
		else {
			return 'English'
		}
	}


	static createEmptyMedia( currentUser = { shortstop: { default_language: 'en', default_editions: [], default_categories: [] } } ) {
		let language = 'en'
		if ( helpers.doesExist( currentUser.shortstop.default_language ) ) language = currentUser.shortstop.default_language

		let editions = []
		if ( helpers.doesExist( currentUser.shortstop.default_editions ) ) editions = currentUser.shortstop.default_editions

		let initialPostContent = {
			is_test			: false,
			shortstop: {
				language	: language,
				editions	: editions,
				categories	: currentUser.shortstop.default_categories
			},
			smile_content: false,
			group_id: null
		}

		return new Media( initialPostContent, currentUser )
	}


	static createEmptyPhotoAttachment() {
		let attachment = {}
		attachment.kind = 'photo'
		attachment.formats = new Array( 3 )

		for ( let i = 0; i < attachment.formats.length; i++ ) {
			attachment.formats[i] = {}
			attachment.formats[i].content_type = ''
			attachment.formats[i].url = ''
			attachment.formats[i].metadata = {
				height: 0,
				width: 0,
				orientation_flag: ''
			}
		}

		attachment.formats[0].display_type = 'original'
		attachment.formats[1].display_type = 'resized'
		attachment.formats[2].display_type = 'thumbnail'
		return attachment
	}


	static createEmptyVideoAttachment() {
		let attachment = {}
		attachment.kind = 'video'
		attachment.formats = new Array( 1 )
		attachment.formats[0] = {}
		attachment.formats[0].content_type = ''
		attachment.formats[0].display_type = ''
		attachment.formats[0].url = ''
		attachment.formats[0].metadata = {
			height: '',
			width: '',
			duration: '',
			orientation_flag: '',
			aspect_ratio: ''
		}

		return attachment
	}


	constructor( json = {}, user ) {
		if ( helpers.doesExist( json._id ) ) this._id = json._id

		this.type = 'shortstop'
		if ( helpers.doesExist( json.type ) ) this.type = json.type

		if ( helpers.doesExist( json.is_test ) ) this.is_test = json.is_test

		this.shortstop = {
			client				: 'web',
			body_text			: '',
			media_type			: 'text',
			categories			: [],
			facebook_sharing	: { cross_publish: false },
			twitter_sharing		: { body_text: '', cross_publish: false, include_now_url: true },
		}

		if ( helpers.doesExist( user ) ) {
			this.shortstop.facebook_sharing.cross_publish = _get( user, 'shortstop.social_tokens.facebook.cross_publish', false )
			this.shortstop.twitter_sharing.cross_publish = _get( user, 'shortstop.social_tokens.twitter.cross_publish', false )
			this.shortstop.twitter_sharing.include_now_url = _get( user, 'shortstop.social_tokens.twitter.include_now_url', true )
			this.shortstop.twitter_sharing.edit_tweet_in_ui = _get( user, 'shortstop.social_tokens.twitter.edit_tweet_in_ui', true )
		}

		if ( helpers.doesExist( json.shortstop ) ) {
			this.shortstop.language = 'en'
			this.shortstop.editions = []

			if ( helpers.doesExist( json.shortstop.client ) ) this.shortstop.client = json.shortstop.client
			if ( helpers.doesExist( json.shortstop.language ) ) this.shortstop.language = json.shortstop.language
			if ( helpers.doesExist( json.shortstop.editions ) ) this.shortstop.editions = json.shortstop.editions
			if ( helpers.doesExist( json.shortstop.body_text ) ) this.shortstop.body_text = json.shortstop.body_text
			if ( helpers.doesExist( json.shortstop.media_type ) ) this.shortstop.media_type = json.shortstop.media_type
			if ( helpers.doesExist( json.shortstop.categories ) ) this.shortstop.categories = json.shortstop.categories
			if ( helpers.doesExist( json.shortstop.now_url ) ) this.shortstop.now_url = json.shortstop.now_url
			if ( helpers.doesExist( json.shortstop.event ) ) this.shortstop.event = json.shortstop.event
			if ( helpers.doesExist( json.shortstop.facebook_sharing ) ) this.shortstop.facebook_sharing = json.shortstop.facebook_sharing
			if ( helpers.doesExist( json.shortstop.twitter_sharing ) ) this.shortstop.twitter_sharing = json.shortstop.twitter_sharing
		}

		this.attachments = []
		if ( helpers.doesExist( json.attachments ) ) this.attachments = json.attachments
		if ( helpers.doesExist( json.smile_content ) ) this.smile_content = json.smile_content
		if ( helpers.doesExist( json.group_id ) ) this.group_id = json.group_id
		if ( helpers.doesExist( json.user ) ) this.user = json.user
		if ( helpers.doesExist( json.group_organization ) ) this.group_organization = json.group_organization
		if ( helpers.doesExist( json.workflow ) ) this.workflow = json.workflow
		if ( helpers.doesExist( json.created ) ) this.created = json.created
		if ( helpers.doesExist( json[ '$ref' ] ) ) this[ '$ref' ] = json[ '$ref' ]
	}


	get modelName() { return Media.modelName }
	get friendlyName() { return Media.friendlyName }
	get workItemType() { return Media.workItemType }


	getAttachment( kind ) {
		return this.attachments.find( ( a ) => { return a.kind === kind } )
	}


	getVideoUrl() {
		const video = this.getAttachment( 'video' )
		if ( helpers.doesExist( video ) ) {
			return video.formats[ 0 ].url
		}
	}


	getThumbnailUrl( ) {
		const photo = this.getAttachment( 'photo' )

		let url = null

		if ( helpers.doesExist( photo ) ) {
			for ( let format of photo.formats ) {
				if ( format.display_type === 'resized' ) {
					// if there is a resized version, use that
					url = format.url
					break
				}
			}
		}

		return url
	}


	get isValid() {
		if ( this.shortstop.body_text.length === 0 ) return false
		if ( this.shortstop.categories.length === 0 ) return false
		if ( this.smile_content === true && this.attachments.length === 0 ) return false

		return true
	}
}
