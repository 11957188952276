import React from 'react'
import PropTypes from 'prop-types'

import QS from 'query-string'
import Cookies from 'js-cookie'

import API from '../../lib/api'


class TwitterCallback extends React.Component {
	constructor( props ) {
		super( props )

		this.API = API
		if ( props.api ) this.API = props.api
	}


	async componentDidMount() {
		await this.getOauthTokens()
		if ( this.props.mounted ) this.props.mounted()
	}


	async getOauthTokens() {
		try {
			let query = QS.parse( this.props.routeProps.location.search )

			let requestToken = Cookies.get( 'twitter-request-token' )
			let requestTokenSecret = Cookies.get( 'twitter-request-token-secret' )

			Cookies.remove( 'twitter-request-token' )
			Cookies.remove( 'twitter-request-token-secret' )

			let params = {
				request_token			: requestToken,
				request_token_secret	: requestTokenSecret,
				oauth_verifier			: query.oauth_verifier
			}

			let res = await this.API.Auth.getTwitterOAuthToken( this.props.currentUser, params )
			await this.associateAccount( res.oauth_token, res.oauth_token_secret )
		}
		catch ( e ) {
			this.props.showErrorDialog( 'Twitter Account Association', e )
		}
	}


	async associateAccount( oauthToken, oauthTokenSecret ) {
		try {
			await this.props.addTwitterToCurrentUser( oauthToken, oauthTokenSecret )
			this.props.routeProps.history.push( '/settings' )
		}
		catch ( e ) {
			this.props.showErrorDialog( 'Twitter Account Association', e )
		}
	}


	render() {
		return (
			<div />
		)
	}
}


TwitterCallback.propTypes = {
	currentUser				: PropTypes.object.isRequired,
	addTwitterToCurrentUser	: PropTypes.func.isRequired,
	showErrorDialog			: PropTypes.func.isRequired,

	// provided by react router
	routeProps				: PropTypes.object.isRequired,

	// test hooks
	api						: PropTypes.object,
	mounted					: PropTypes.func,
}


export default TwitterCallback
