import React from 'react'
import PropTypes from 'prop-types'
import helpers from '../lib/helpers'

import { withStyles } from '@material-ui/core/styles'

import LoginManager from '../lib/login_manager'
import LocalStorage from '../lib/local_storage'
import Header from './header'
import AppDrawer from './app_drawer'
import ErrorDialog from './common/error_dialog'
import AboutDialog from './about_dialog'
import WhatsNewDialog from './whats_new_dialog'
import ProgressDialog from './common/progress_dialog'
import SimpleConfirmationDialog from './common/simple_confirmation_dialog'
import AppNotification from './common/app_notification'
import Create from './pages/create'
import MyPosts from './pages/my_posts'
import SmileUploads from './pages/smile_uploads'
import Settings from './pages/settings'
import Drafts from './pages/drafts'
// import Commentary from './pages/commentary'
// import ReporterEvents from './pages/reporter_events'
import TwitterCallback from './pages/twitter_callback'

import API from '../lib/api'
import moment from 'moment'
import config from '../lib/config'


const styles = ( theme ) => ( {
	outer_div		: {
		display			: 'flex',
	},
	main_content	: {
		flexGrow		: 1,
		padding			: '20px',
		height			: '100%',
		overflow		: 'auto',
		[theme.breakpoints.down( 'sm' )] : {
			padding : 0
		}
	}
} )


class App extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			current_user		: null,
			drawer_open			: false,
			confirmation_dialog	: { open: false, title: '', text: '' },
			progress_dialog		: { open: false, title: '', text: '' },
			error_dialog		: { open: false, title: '', error: null, callback: null },
			about_dialog		: { open: false },
			whatsNew_dialog		: { open: false },
			notification		: { message: null }
		}

		this.showAboutDialog = this.showAboutDialog.bind( this )
		this.handleLogOut = this.handleLogOut.bind( this )
		this.showProgressDialog = this.showProgressDialog.bind( this )
		this.closeProgressDialog = this.closeProgressDialog.bind( this )
		this.showNotification = this.showNotification.bind( this )
		this.updateCurrentUser = this.updateCurrentUser.bind( this )
		this.addTwitterToCurrentUser = this.addTwitterToCurrentUser.bind( this )
		this.showErrorDialog = this.showErrorDialog.bind( this )
		this.closeConfirmationDialog = this.closeConfirmationDialog.bind( this )
		this.closeErrorDialog = this.closeErrorDialog.bind( this )
		this.closeAboutDialog = this.closeAboutDialog.bind( this )
		this.handleOpenDrawer = this.handleOpenDrawer.bind( this )
		this.handleCloseDrawer = this.handleCloseDrawer.bind( this )
		this.notificationDismissed = this.notificationDismissed.bind( this )
		this.showWhatsNewDialog = this.showWhatsNewDialog.bind( this )
		this.closeWhatsNewDialog = this.closeWhatsNewDialog.bind( this )
	}


	get loginManager() { return this._loginManager }


	componentDidMount() {
		this._loginManager = new LoginManager( window )
		if ( this.props.loginManager ) {
			this._loginManager = new this.props.loginManager()	// eslint-disable-line new-cap
		}

		this._storage = new LocalStorage( window )

		this.loginManager.on( 'authenticated', ( user ) => { this.loginManager_onAuthenticated( user ) } )
		this.loginManager.on( 'unauthenticated', async () => { await this.loginManager_onUnauthenticated() } )
		this.loginManager.on( 'unauthorized', ( user ) => { this.loginManager_onUnauthorized( user ) } )
		this.loginManager.on( 'expired', async ( showDialog ) => { await this.loginManager_onExpired( showDialog ) } )
		this.loginManager.on( 'error', ( error ) => { this.loginManager_onError( error ) } )

		this.loginManager.initialize()

		window.addEventListener( 'pagehide', ( event ) => {
			this._storage.windowClosedTimeStamp = moment()
		} )
	}


	/* eslint-disable camelcase */

	async loginManager_onAuthenticated( user ) {
		this.setState( { current_user: user }, async () => {
			try {
				let metadata = await API.User.getUserMetadata( user )

				if ( !metadata ) {
					// create the user_metadata document if it does not exist

					let userMetadata = {
						user: user.toSnapshot(),
						web_client: {
							version: config.appVersion
						}
					}

					await API.User.createUserMetadata( user, userMetadata )

					this.setState( { whatsNew_dialog: { open: true } } )
				}
				else if ( helpers.doesExist( metadata ) && ( helpers.doesNotExist( metadata.web_client ) ) ) {
					// update existing metadata document with web client sub doc
					metadata.web_client = {
						version: config.appVersion
					}

					await API.User.updateUserMetadata( user, metadata )

					this.setState( { whatsNew_dialog: { open: true } } )
				}
				else if ( helpers.doesExist( metadata ) && ( helpers.doesExist( metadata.web_client ) ) ) {
					if ( metadata.web_client.version !== config.appVersion ) {
						// update metadata record

						metadata.web_client.version = config.appVersion

						await API.User.updateUserMetadata( user, metadata )

						this.setState( { whatsNew_dialog: { open: true } } )
					}
				}
			}
			catch ( error ) {
				await this.showErrorDialog( 'Server Error', error )
			}
		} )
	}


	async loginManager_onUnauthenticated() {
		await this.login()
	}

	async loginManager_onUnauthorized( guestEmail ) {
		this.setState( {
			confirmation_dialog: {
				open	: true,
				title	: 'Unauthorized User',
				text	: `The user ${ guestEmail } is not an authorized Shortstop user.  Please contact support at ESPNDL-Live@espn.com`
			}
		} )
	}


	async loginManager_onExpired( showDialog ) {
		if ( showDialog ) {
			return this.setState( {
				confirmation_dialog: {
					open	: true,
					title	: 'Session Inactivity Timeout',
					text	: 'Your session has expired due to inactivity.  Please log in again.'
				}
			} )
		}

		await this.login()
	}


	async loginManager_onError( error ) {
		await this.showErrorDialog( 'Server Error', error, this.login.bind( this ) )
	}

	/* eslint-enable camelcase */


	closeConfirmationDialog() {
		this.setState( { confirmation_dialog: { open: false, title: '', text: '' } }, async () => {
			await this.login()
		} )
	}


	handleOpenDrawer() {
		this.setState( { drawer_open: true } )
	}


	handleCloseDrawer() {
		this.setState( { drawer_open: false } )
	}


	async handleLogOut() {
		await this.loginManager.logout()
	}


	async login() {
		return new Promise( ( resolve, reject ) => {
			this.setState( { current_user: null }, async () => {
				await this.loginManager.login()
				resolve()
			} )
		} )
	}


	async updateCurrentUser( user ) {
		try {
			await this.showProgressDialog( 'Saving User', 'Saving changes to the user record' )
			let updatedUser = await API.User.updateUser( user )
			this.setState( { current_user: updatedUser }, async () => {
				this._storage.currentUser = this.state.current_user
				await this.closeProgressDialog()
			} )
		}
		catch ( e ) {
			await this.closeProgressDialog()
			await this.showErrorDialog( 'Saving User', e )
		}
	}


	async addTwitterToCurrentUser( oauthToken, oauthTokenSecret ) {
		try {
			await this.showProgressDialog( 'Saving User', 'Saving changes to the user record' )

			let params = { oauth_token: oauthToken, oauth_token_secret: oauthTokenSecret }
			let updatedUser = await API.Auth.addTwitterSocialTokensToUser( this.state.current_user, this.state.current_user._id, params )
			this.setState( { current_user: updatedUser }, async () => {
				this._storage.currentUser = this.state.current_user
				await this.closeProgressDialog()
			} )
		}
		catch ( e ) {
			await this.closeProgressDialog()
			await this.showErrorDialog( 'Saving User', e )
		}
	}


	showProgressDialog( title, text ) {
		return new Promise( ( resolve, reject ) => {
			this.setState( { progress_dialog: { open: true, title: title, text: text } }, () => { resolve() } )
		} )
	}


	closeProgressDialog() {
		return new Promise( ( resolve, reject ) => {
			this.setState( { progress_dialog: { open: false, title: '', text: '' } }, () => { resolve() } )
		} )
	}


	showErrorDialog( title, error, callback = null ) {
		return new Promise( ( resolve, reject ) => {
			this.setState( { error_dialog: { open: true, title: title, error: error, callback: callback } }, () => { resolve() } )
		} )
	}


	closeErrorDialog() {
		return new Promise( ( resolve, reject ) => {
			let closeCallback = this.state.error_dialog.callback

			this.setState( { error_dialog: { open: false, title: '', error: null, callback: null } }, async () => {
				if ( closeCallback ) await closeCallback()
				resolve()
			} )
		} )
	}


	showAboutDialog() {
		return new Promise( ( resolve, reject ) => {
			this.setState( { about_dialog: { open: true } }, () => { resolve() } )
		} )
	}


	closeAboutDialog() {
		return new Promise( ( resolve, reject ) => {
			this.setState( { about_dialog: { open: false } }, () => { resolve() } )
		} )
	}


	showWhatsNewDialog() {
		return new Promise( ( resolve, reject ) => {
			this.setState( { whatsNew_dialog: { open: true } }, () => { resolve() } )
		} )
	}


	closeWhatsNewDialog() {
		return new Promise( ( resolve, reject ) => {
			this.setState( { whatsNew_dialog: { open: false } }, () => { resolve() } )
		} )
	}


	showNotification( message, dismissCallback = null ) {
		this.setState( { notification: { message: message } } )
	}


	notificationDismissed() {
		this.setState( { notification: { message: null } } )
	}


	renderPageForLocation() {
		let page = ( <div /> )

		if ( this.props.routeProps.location.pathname === '/' ) {
			page = (
				<MyPosts
					currentUser={ this.state.current_user }
					showErrorDialog={ this.showErrorDialog }
					showProgressDialog={ this.showProgressDialog }
					closeProgressDialog={ this.closeProgressDialog }
					routeProps={ this.props.routeProps }
				/>
			)
		}
		else if ( this.props.routeProps.location.pathname === '/smile' ) {
			page = (
				<SmileUploads
					currentUser={ this.state.current_user }
					showErrorDialog={ this.showErrorDialog }
					showProgressDialog={ this.showProgressDialog }
					closeProgressDialog={ this.closeProgressDialog }
					routeProps={ this.props.routeProps }
				/>
			)
		}
		else if ( this.props.routeProps.location.pathname.indexOf( '/compose' ) > -1 || this.props.routeProps.location.pathname.indexOf( '/edit' ) > -1 ) {
			page = (
				<Create
					currentUser={ this.state.current_user }
					showErrorDialog={ this.showErrorDialog }
					showNotification={ this.showNotification }
					routeProps={ this.props.routeProps }
				/>
			)
		}
		else if ( this.props.routeProps.location.pathname === '/drafts' ) {
			page = (
				<Drafts
					currentUser={ this.state.current_user }
					showErrorDialog={ this.showErrorDialog }
					showProgressDialog={ this.showProgressDialog }
					closeProgressDialog={ this.closeProgressDialog }
					showNotification={ this.showNotification }
					routeProps={ this.props.routeProps }
				/>
			)
		}
		// else if ( this.props.routeProps.location.pathname.indexOf( '/commentary' ) > -1 ) {
		// 	page = (
		// 		<Commentary
		// 			currentUser={ this.state.current_user }
		// 			showErrorDialog={ this.showErrorDialog }
		// 			showProgressDialog={ this.showProgressDialog }
		// 			closeProgressDialog={ this.closeProgressDialog }
		// 			showNotification={ this.showNotification }
		// 			routeProps={ this.props.routeProps }
		// 		/>
		// 	)
		// }
		// else if ( this.props.routeProps.location.pathname.indexOf( '/events' ) > -1 ) {
		// 	page = (
		// 		<ReporterEvents
		// 			currentUser={ this.state.current_user }
		// 			showErrorDialog={ this.showErrorDialog }
		// 			showProgressDialog={ this.showProgressDialog }
		// 			closeProgressDialog={ this.closeProgressDialog }
		// 			routeProps={ this.props.routeProps }
		// 		/>
		// 	)
		// }
		else if ( this.props.routeProps.location.pathname.indexOf( '/settings/twitter/callback' ) > -1 ) {
			page = (
				<TwitterCallback
					currentUser={ this.state.current_user }
					addTwitterToCurrentUser={ this.addTwitterToCurrentUser }
					showErrorDialog={ this.showErrorDialog }
					routeProps={ this.props.routeProps }
				/>
			)
		}
		else if ( this.props.routeProps.location.pathname.indexOf( '/settings' ) > -1 ) {
			page = (
				<Settings currentUser={ this.state.current_user } updateCurrentUser={ this.updateCurrentUser } showErrorDialog={ this.showErrorDialog } />
			)
		}

		return page
	}


	render() {
		let dialog = ( <div /> )


		if ( this.state.confirmation_dialog.open ) {
			dialog = (
				<SimpleConfirmationDialog
					isOpen={ this.state.confirmation_dialog.open }
					dialogTitle={ this.state.confirmation_dialog.title }
					dialogText={ this.state.confirmation_dialog.text }
					closeDialog={ this.closeConfirmationDialog }
				/>
			)
		}
		else if ( this.state.progress_dialog.open ) {
			dialog = (
				<ProgressDialog
					isOpen={ this.state.progress_dialog.open }
					dialogTitle={ this.state.progress_dialog.title }
					dialogText={ this.state.progress_dialog.text }
				/>
			)
		}
		else if ( this.state.error_dialog.open ) {
			dialog = (
				<ErrorDialog
					isOpen={ this.state.error_dialog.open }
					dialogTitle={ this.state.error_dialog.title }
					error={ this.state.error_dialog.error }
					closeDialog={ this.closeErrorDialog }
				/>
			)
		}
		else if ( this.state.whatsNew_dialog.open ) {
			dialog = (
				<WhatsNewDialog
					isOpen={ this.state.whatsNew_dialog.open }
					closeDialog={ this.closeWhatsNewDialog }
				/>
			)
		}
		else if ( this.state.about_dialog.open ) {
			dialog = (
				<AboutDialog
					isOpen={ this.state.about_dialog.open }
					closeDialog={ this.closeAboutDialog }
				/>
			)
		}

		if ( this.state.current_user === null ) {
			return (
				<div>
					{ dialog }
				</div>
			)
		}

		let notificationOpen = false
		if ( this.state.notification.message !== null ) notificationOpen = true

		return (
			<div className={ this.props.classes.outer_div }>
				<Header drawerOpen={ this.state.drawer_open } openDrawer={ this.handleOpenDrawer } currentUser={ this.state.current_user } />

				<AppDrawer
					isOpen={ this.state.drawer_open }
					closeDrawer={ this.handleCloseDrawer }
					logOut={ this.handleLogOut }
					openAbout={ this.showAboutDialog }
					routeProps={ this.props.routeProps }
				/>

				{ dialog }

				<AppNotification notification={ this.state.notification } open={ notificationOpen } notificationDismissed={ this.notificationDismissed } />

				<main className={ this.props.classes.main_content }>
					<div style={ { minHeight: '64px' } } />
					{ this.renderPageForLocation() }
				</main>
			</div>
		)
	}
}


App.propTypes = {
	// test hooks
	loginManager	: PropTypes.func,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,

	// provided by react router
	routeProps		: PropTypes.object.isRequired,
}


export default withStyles( styles )( App )
