import React from 'react'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core'


const styles = ( theme ) => ( {
	textField : {
		[theme.breakpoints.down( 'sm' )] : {
			width: '250px'
		}
	}

} )


class PostContent extends React.Component {
	constructor( props ) {
		super( props )
		this.updateBodyText = this.updateBodyText.bind( this )
	}


	updateBodyText( event ) {
		if ( event.target.value.endsWith( '  ' ) ) return

		let newPost = _cloneDeep( this.props.post )

		try {
			newPost.shortstop.body_text = event.target.value.trimLeft()

			// for now, set the twitter body_text to equal the post text
			newPost.shortstop.twitter_sharing.body_text = newPost.shortstop.body_text
		}
		catch ( e ) {
			// for some reason, trimStart() is failing on the build server
		}

		if ( newPost.shortstop.body_text.length > 500 ) return

		this.props.updatePostState( newPost )
	}


	render() {
		let error = true
		let helpText = 'Post must contain some content'

		if ( this.props.post.shortstop.body_text.length > 0 ) {
			error = false
			helpText = `Character count: ${ this.props.post.shortstop.body_text.length } (Max: 500)`

			if ( this.props.post.shortstop.body_text.length === 500 ) error = true
		}

		return (
			<FormControl error={ error } fullWidth>
				<TextField
					className={ this.props.classes.textField }
					error={ error }
					placeholder="Happening now..."
					multiline
					rowsMax={ 50 }
					value={ this.props.post.shortstop.body_text }
					onChange={ this.updateBodyText }
				/>
				<FormHelperText>{ helpText }</FormHelperText>
			</FormControl>
		)
	}
}


PostContent.propTypes = {
	// component specific
	post			: PropTypes.object.isRequired,
	updatePostState	: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( PostContent )
