import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import Commentary from '../../models/commentary'

import BaseAPI from './_api'


export default class CommentaryAPI {
	static addCommentary( currentUser, commentary ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/commentary`

			BaseAPI.setupRequest( currentUser, request.post( path ) )
				.send( commentary )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Post Commentary' )

					resolve( new Commentary( res.body ) )
				} )
		} )
	}


	static searchCommentary( currentUser, eventId, limit, offset ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/commentary`

			let queryParams = { limit: limit, offset: offset, event_id: eventId }

			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Get Commentary' )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Commentary( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data 	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static updateCommentary( currentUser, commentary ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/commentary/${ commentary._id }`

			BaseAPI.setupRequest( currentUser, request.put( path ) )
				.send( commentary )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Update Commentary' )

					resolve( new Commentary( res.body ) )
				} )
		} )
	}


	static deleteCommentary( currentUser, commentaryId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/commentary/${ commentaryId }`

			BaseAPI.setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Delete Commentary' )

					resolve( new Commentary( res.body ) )
				} )
		} )
	}
}
