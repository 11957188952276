import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { withStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import grey from '@material-ui/core/colors/grey'

import Category from '../../models/category'


const styles = ( theme ) => ( {
	selected_container	: {
		paddingTop	: '5px'
	},
	selected_chip		: {
		marginRight	: '5px',
		marginBottom: '5px',
		padding		: '1px'
	},
} )


class SelectedCategories extends React.Component {
	constructor( props ) {
		super( props )

		this.removeCategory = this.removeCategory.bind( this )
	}


	removeCategory( category ) {
		this.props.categoryRemoved( category )
	}


	render() {
		return (
			<div className={ this.props.classes.selected_container }>
				{
					this.props.selectedCategories.map( ( c ) => {
						let imageUrl = _get( c, 'context.image', null )

						let avatarStyle = {}
						if ( imageUrl === null ) {
							avatarStyle = { backgroundColor: grey[ 400 ] }
						}
						else if ( c.type === 'player' ) {
							avatarStyle = { border: `1px solid ${ grey[ 400 ] }`, height: 30, width: 30 }
						}

						let avatar = ( <Avatar alt={ c.name } src={ imageUrl } style={ avatarStyle } /> )

						return (
							<Chip
								id="selected-category"
								key={ c.now_id }
								className={ this.props.classes.selected_chip }
								label={
									<div>
										<Typography variant="body1" style={ { marginBottom: -6 } }>{ c.name }</Typography>
										<Typography variant="caption" style={ { marginBottom: -1 } }>{ Category.getLeague( c ) }</Typography>
									</div>
								}
								avatar={ avatar }
								onDelete={ () => { this.removeCategory( c ) } }
							/>
						)
					} )
				}
			</div>
		)
	}
}


SelectedCategories.propTypes = {
	// component specific
	selectedCategories	: PropTypes.array.isRequired,
	categoryRemoved		: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,
}


export default withStyles( styles )( SelectedCategories )
