import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import PostPreview from '../common/post_preview'


const styles = ( theme ) => ( {
	post_container	: {
		display			: 'flex',
		flexWrap		: 'wrap',
		marginTop		: 10,
		[theme.breakpoints.down( 'sm' )] : {
			marginTop 	: 0
		}
	},
	post			: {
		marginRight		: 20,
		marginBottom	: 20,
		[theme.breakpoints.down( 'sm' )] : {
			marginRight : 8,
			marginBottom: 8,
			marginLeft	: 8,
			width		: '100%'
		}
	},
} )


class TimelineLayout extends React.Component {
	render() {
		let currentUser = this.props.currentUser

		let timelineData = this.props.timeline.data
		if ( !currentUser.shortstop.dev_mode ) {
			timelineData = timelineData.filter( post => !post.is_test )
		}

		return (
			<div className={ this.props.classes.post_container }>
				{
					timelineData.map( ( post ) => {
						return (
							<div key={ post._id } className={ this.props.classes.post }>
								<PostPreview
									currentUser={ this.props.currentUser }
									post={ post }
									displayMenu
									deletePost={ this.props.deletePost }
									sharePost={ this.props.sharePost }
									editPost={ this.props.editPost }
									showGroupDialog={ this.props.showGroupDialog }
									hideDeleteButton={ post.smile_content }
									hideShareButton={ post.smile_content }
								/>
							</div>
						)
					} )
				}
			</div>
		)
	}
}


TimelineLayout.propTypes = {
	currentUser		: PropTypes.object.isRequired,
	timeline		: PropTypes.object.isRequired,
	deletePost		: PropTypes.func,
	sharePost		: PropTypes.func,
	editPost		: PropTypes.func,
	showGroupDialog	: PropTypes.func,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}

TimelineLayout.defaultProps = {
	deletePost		: () => {},
	sharePost		: () => {},
	editPost		: () => {},
	showGroupDialog  : () => {}
}

export default withStyles( styles )( TimelineLayout )
