import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import BaseAPI from './_api'


export default class AWSAPI {
	static getPreSignedUrl( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/media/get-upload-url`

			BaseAPI.setupRequest( currentUser, request.put( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Unable to get presigned url for upload for user: ${ currentUser._id } on aws` )
					resolve( res )
				} )
		} )
	}


	static uploadToAws( currentUser, workItem, url, type ) {
		return new Promise( ( resolve, reject ) => {
			request.put( url )
				.set( 'Content-Type', type )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						// don't call the standard error handler here since AWS returns XML

						/* eslint-disable prefer-promise-reject-errors */
						return reject( { _apiMethod: `Unable to upload local file for upload for user: ${ currentUser._id } on aws`, _isServerException: false } )
						/* eslint-enable prefer-promise-reject-errors */
					}

					resolve( res )
				} )
		} )
	}
}
