import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import API from '../../lib/api'
import config from '../../lib/config'


export const styles = ( theme ) => ( {
	outer_div		: {
		marginTop	: 20
	},
	connected_container	: {
		width		: '100%'
	},
	connected_container_mobile	: {
		width		: '100%'
	},
	connected_list_item	: {
		marginLeft	: -15,
	},
	connected_list_item_mobile	: {
		marginLeft	: -10,
	},
	option_switch	: {
		marginLeft	: 20,
	},
	option_switch_mobile	: {
		marginLeft	: 0,
	},
	button			: {
		marginTop	: 10,
		marginBottom: 10,
	},
} )


export class TwitterSettings extends React.Component {
	constructor( props ) {
		super( props )

		this.API = API
		if ( props.api ) this.API = props.api

		this.toggleCrossPublishing = this.toggleCrossPublishing.bind( this )
		this.removeTwitterAssociation = this.removeTwitterAssociation.bind( this )
		this.handleAddAccount = this.handleAddAccount.bind( this )
		this.changeOption = this.changeOption.bind( this )
	}


	async loginToTwitter() {
		try {
			let callback = `${ config.clientHost }/settings/twitter/callback`
			let result = await this.API.Auth.getTwitterAuthEndpoint( this.props.currentUser, callback )

			Cookies.set( 'twitter-request-token', result.request_token )
			Cookies.set( 'twitter-request-token-secret', result.request_token_secret )

			window.location.href = result.auth_url
		}
		catch ( e ) {
			this.props.showErrorDialog( 'Twitter Account Association', e )
		}
	}


	async handleAddAccount() {
		await this.loginToTwitter()
	}


	removeTwitterAssociation() {
		this.props.updateTwitterSettings( undefined )
	}


	toggleCrossPublishing( event ) {
		let newSettings = _cloneDeep( this.props.twitterSettings )
		newSettings.cross_publish = event.target.checked

		this.props.updateTwitterSettings( newSettings )
	}

	changeOption( event ) {
		let newSettings = _cloneDeep( this.props.twitterSettings )
		if ( event.target.value === 'edit' ) {
			newSettings.include_now_url = true
			newSettings.edit_tweet_in_ui = true
		}
		else if ( event.target.value === 'auto-with' ) {
			newSettings.include_now_url = true
			newSettings.edit_tweet_in_ui = false
		}
		else {
			newSettings.include_now_url = false
			newSettings.edit_tweet_in_ui = false
		}

		this.props.updateTwitterSettings( newSettings )
	}

	renderConnected() {
		let accountName = _get( this.props.twitterSettings, 'screen_name', '' )
		let accountAvatar = _get( this.props.twitterSettings, 'avatar', null )
		let accountId = _get( this.props.twitterSettings, 'account_id', '' )
		let crossPublish = _get( this.props.twitterSettings, 'cross_publish', false )
		let twitterIncludeUrl = _get( this.props.twitterSettings, 'include_now_url', true )
		let twitterEditInUI = _get( this.props.twitterSettings, 'edit_tweet_in_ui', true )
		let twitterOption = 'edit'

		if ( twitterEditInUI ) {
			twitterOption = 'edit'
		}
		else {
			if ( twitterIncludeUrl ) {
				twitterOption = 'auto-with'
			}
			else {
				twitterOption = 'auto-without'
			}
		}


		let avatar = ( <div /> )
		if ( accountAvatar !== null ) avatar = ( <Avatar src={ accountAvatar } /> )

		return (
			<div>
				<Hidden only={ [ 'xs', 'sm', 'md' ] }>
					<div className={ this.props.classes.connected_container }>
						<ListItem className={ this.props.classes.connected_list_item } ContainerComponent="div">
							<Grid justify="flex-start" container>
								<Grid item>
									<Grid container direction="row" alignItems="center">
										<Grid item>
											{ avatar }
										</Grid>
										<Grid item>
											<Box m={ 1 }>
												<ListItemText primary={ accountName } secondary={ accountId } />
											</Box>
										</Grid>
										<Grid item>
											<Switch
												onChange={ this.toggleCrossPublishing }
												checked={ crossPublish }
												color="primary"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Box ml={ 10 }>
										<FormControl className={ this.props.classes.option_switch }>
											<FormLabel id="twitter-options-select-label" component="legend">Options</FormLabel>
											<RadioGroup
												aria-label="twitter-options"
												id="twitter-options-select"
												labelId="twitter-options-select-label"
												value={ twitterOption }
												onChange={ this.changeOption }
											>
												<FormControlLabel value={ 'edit' } control={ <Radio /> } label="Edit Tweet" />
												<FormControlLabel value={ 'auto-with' } control={ <Radio /> } label="Auto Tweet with Shortstop URL" />
												<FormControlLabel value={ 'auto-without' } control={ <Radio /> } label="Auto Tweet without Shortstop URL" />
											</RadioGroup>
										</FormControl>
									</Box>
								</Grid>
								<Grid item xs={ 12 }>
									<Button className={ this.props.classes.button } variant="contained" onClick={ this.removeTwitterAssociation }>Remove Account</Button>
								</Grid>
							</Grid>
						</ListItem>
					</div>
				</Hidden>

				<Hidden only={ [ 'lg', 'xl' ] }>
					<div className={ this.props.classes.connected_container_mobile }>
						<ListItem ContainerComponent="div">
							<Grid justify="space-between" container space={ 5 }>
								<Grid item>
									<Box>
										<Grid container direction="row" alignItems="center">
											<Grid item>
												{ avatar }
											</Grid>
											<Grid item>
												<Box m={ 1 }>
													<ListItemText primary={ accountName } secondary={ accountId } />
												</Box>
											</Grid>
											<Grid item>
												<Switch
													onChange={ this.toggleCrossPublishing }
													checked={ crossPublish }
													color="primary"
												/>
											</Grid>
										</Grid>
									</Box>
								</Grid>
								<Grid item>
									<FormControl className={ this.props.classes.option_switch_mobile }>
										<FormLabel id="twitter-options-select-label" component="legend">Options</FormLabel>
										<RadioGroup
											aria-label="twitter-options"
											id="twitter-options-select"
											labelId="twitter-options-select-label"
											value={ twitterOption }
											onChange={ this.changeOption }
										>
											<FormControlLabel value={ 'edit' } control={ <Radio /> } label="Edit Tweet" />
											<FormControlLabel value={ 'auto-with' } control={ <Radio /> } label="Auto Tweet with Shortstop URL" />
											<FormControlLabel value={ 'auto-without' } control={ <Radio /> } label="Auto Tweet without Shortstop URL" />
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={ 12 }>
									<Button className={ this.props.classes.button } variant="contained" onClick={ this.removeTwitterAssociation }>Remove Account</Button>
								</Grid>
							</Grid>
						</ListItem>
					</div>
				</Hidden>
			</div>
		)
	}


	renderDisconnected() {
		return (
			<Button variant="contained" onClick={ this.handleAddAccount }>Add Account</Button>
		)
	}


	render() {
		let view = ( <div /> )
		if ( this.props.twitterSettings ) {
			view = this.renderConnected()
		}
		else {
			view = this.renderDisconnected()
		}

		return (
			<div className={ this.props.classes.outer_div }>
				{ view }
			</div>
		)
	}
}


TwitterSettings.propTypes = {
	currentUser				: PropTypes.object.isRequired,
	showErrorDialog			: PropTypes.func.isRequired,

	// component specific
	twitterSettings			: PropTypes.object,
	updateTwitterSettings	: PropTypes.func.isRequired,

	// test hooks
	api					: PropTypes.object,

	// injected by material-ui
	classes					: PropTypes.object.isRequired,
}


export default withStyles( styles )( TwitterSettings )
