import helpers from '../lib/helpers'

export default class ReporterEvent {
	static get friendlyName() { return 'Reporter Event' }
	static get modelName() { return ReporterEvent.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return ReporterEvent.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this._id = json._id
		this.event = json.event
		this.language = json.language
		this.user = json.user
		this.created = json.created
	}


	get modelName() { return ReporterEvent.modelName }
	get friendlyName() { return ReporterEvent.friendlyName }
	get workItemType() { return ReporterEvent.workItemType }

	toSnapshot() {
		if ( helpers.doesNotExist( this._id ) ) return null

		let snapshot = {
			reporter_event_id	: this._id,
			event_id			: this.event.event_id,
			event_name			: this.event.name,
			language			: this.language
		}

		return snapshot
	}
}
