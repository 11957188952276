import _get from 'lodash/get'

import helpers from '../../lib/helpers'


export default class BaseAPI {
	static setupRequest( currentUser, req ) {
		req.set( 'Authorization', `Bearer ${ currentUser.access_tokens.bearer }` )
			.set( 'x-mex-user-id', currentUser._id )
			.type( 'application/json' )
			.accept( 'json' )

		return req
	}


	static handleError( err, res, reject, apiMethod, extraProps = {} ) {
		let body = null
		if ( helpers.doesExist( res ) ) body = _get( res, 'body', null )

		let statusCode = 500
		if ( helpers.doesExist( res ) ) statusCode = _get( res, 'statusCode', 500 )

		if ( body !== null ) return reject( Object.assign( res.body, { _apiMethod: apiMethod, _isServerException: false, statusCode: statusCode }, extraProps ) )

		return reject( Object.assign( err, { _apiMethod: apiMethod, _isServerException: true }, extraProps ) )
	}
}
