import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'

import config from '../lib/config'
import helpers from '../lib/helpers'


const DIALOG_WIDTH = 300

const styles = ( theme ) => ( {
	content				: {
		display				: 'flex',
		flexDirection		: 'column',
		minWidth			: DIALOG_WIDTH,
	},
	content_upper_row	: {
		display				: 'flex',
		flexDirection		: 'row',
	},
	upper_row_list		: {
		minWidth			: DIALOG_WIDTH / 2,
	},
	content_item		: {
		marginLeft			: '-24px'
	},
	dialog_actions 				: {
		[theme.breakpoints.down( 'sm' )] : {
			marginBottom	: 40,
			marginRight		: 40
		}
	},
	title				: {
		paddingLeft: 16
	}
} )


class AboutDialog extends React.Component {
	render() {
		if ( !this.props.isOpen ) return ( <div /> )

		let environment = config.environment.charAt( 0 ).toUpperCase() + config.environment.slice( 1 ).toLowerCase()
		if ( config.environment === 'qa' ) environment = 'QA'

		return (
			<Dialog
				open={ this.props.isOpen }
				onClose={ this.props.closeDialog }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle className={ this.props.classes.title } id="dialog-title">About</DialogTitle>
				<DialogContent>
					<div className={ this.props.classes.content }>
						<div className={ this.props.classes.content_upper_row }>
							<List dense className={ this.props.classes.upper_row_list }>
								<ListItem className={ this.props.classes.content_item }><ListItemText primary="Environment" secondary={ environment } /></ListItem>
							</List>
							<List dense>
								<ListItem className={ this.props.classes.content_item }><ListItemText primary="Version" secondary={ config.appVersion } /></ListItem>
							</List>
						</div>
						<List dense>
							<ListItem className={ this.props.classes.content_item }><ListItemText primary="Last Commit" secondary={ `${ config.lastCommitDesc } (${ config.lastCommitSha })` } /></ListItem>
						</List>
					</div>
				</DialogContent>
				<DialogActions className={ this.props.classes.dialog_actions }>
					<Button id="close-dialog" onClick={ this.props.closeDialog } color="primary" variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


AboutDialog.propTypes = {
	isOpen			: PropTypes.bool.isRequired,
	closeDialog		: PropTypes.func.isRequired,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}


export default withStyles( styles )( AboutDialog )
