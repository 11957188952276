import helpers from '../lib/helpers'


export default class User {
	static get friendlyName() { return 'User' }
	static get modelName() { return User.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return User.friendlyName.replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this._id = json._id
		this.username = json.username
		this.state = json.state
		this.contact = json.contact
		this.created = json.created
		this.access_tokens = json.access_tokens
		this.group = json.group

		if ( helpers.doesExist( json.shortstop ) ) {
			this.shortstop = json.shortstop
		}
		else if ( helpers.doesExist( this.group ) && this.group.type === 'shortstop' ) {
			this.shortstop = { shortstop_live_access: false, social_tokens: {}, default_language: 'en', default_editions: [], default_categories: [], dev_mode: false }
		}

		this[ '$ref' ] = json[ '$ref' ]

		if ( helpers.doesNotExist( this.contact ) ) this.contact = {}
		if ( helpers.doesNotExist( this.access_tokens ) ) this.access_tokens = {}
		if ( helpers.doesNotExist( this.group ) ) this.group = {}
		if ( helpers.doesNotExist( this.state ) ) this.state = 'active'
	}


	toSnapshot() {
		if ( helpers.doesNotExist( this._id ) ) return null

		let snapshot = {
			user_id		: this._id,
			username	: this.username,
			name		: this.contact.name
		}

		return snapshot
	}


	get modelName() { return User.modelName }
	get friendlyName() { return User.friendlyName }
	get workItemType() { return User.workItemType }


	get isInternal() {
		if ( helpers.doesNotExist( this.group ) ) return false

		return ( this.group.type === 'administrator' ) || ( this.group.type === 'mx-editorial' )
	}


	get profilePictureUrl() {
		if ( helpers.doesNotExist( this.shortstop ) ) return null

		return `https://secure.espncdn.com/i/columnists/${ this.shortstop.columnist_id }_55.jpg`
	}
}
