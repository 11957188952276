import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'

import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Hidden from '@material-ui/core/Hidden'
import Switch from '@material-ui/core/Switch'


const EDITIONS = [
	{ name: 'Argentina', site_id: 17 },
	{ name: 'Australia', site_id: 16 },
	{ name: 'Brasil', site_id: 26 },
	{ name: 'Chile', site_id: 18 },
	{ name: 'Colombia', site_id: 19 },
	{ name: 'Global', site_id: 25 },
	{ name: 'Mexico', site_id: 20 },
	{ name: 'Philippines', site_id: 30 },
	{ name: 'US (Spanish)', site_id: 22 },
	{ name: 'Venezuela', site_id: 21 },
]


const styles = ( theme ) => ( {
	outer_div				: {
		marginTop		: 20,
	},
	language_select			: {
		[theme.breakpoints.down( 'sm' )] : {
			minWidth: 150
		},
		width			: '50%',
		minWidth		: 300,
	},
	edition_container		: {
		marginTop		: 10,
		display			: 'flex',
		flexDirection	: 'row',
	},
	edition_list			: {
		[theme.breakpoints.down( 'sm' )] : {
			width : '70%'
		},
		width			: '30%',
	},
	edition_list_mobile		: {
		marginLeft		: 10,
	},
	edition_list_spacing	: {
		width			: '30%',
	}
} )


class EditionSelector extends React.Component {
	constructor( props ) {
		super( props )

		this.handleLanguageChange = this.handleLanguageChange.bind( this )
		this.handleEditionToggle = this.handleEditionToggle.bind( this )
	}


	isEditionSelected( editionNumber ) {
		return this.props.currentEditions.indexOf( editionNumber ) > -1
	}


	handleLanguageChange( event ) {
		if ( event.target.value === this.props.currentLanguage ) return
		this.props.updateLanguage( event.target.value )
	}


	handleEditionToggle( editionNumber ) {
		let newEditions = _cloneDeep( this.props.currentEditions )

		if ( this.isEditionSelected( editionNumber ) ) {
			newEditions.splice( newEditions.indexOf( editionNumber ), 1 )
		}
		else {
			newEditions.push( editionNumber )
		}

		this.props.updateEditions( newEditions )
	}


	renderEditionItem( name, siteId ) {
		return (
			<ListItem key={ siteId } className={ this.props.classes.edition_item }>
				<ListItemText primary={ name } />
				<ListItemSecondaryAction>
					<Switch
						checked={ this.isEditionSelected( siteId ) }
						onChange={ () => { this.handleEditionToggle( siteId ) } }
						color="primary"
					/>
				</ListItemSecondaryAction>
			</ListItem>
		)
	}


	renderEditionSelectors() {
		let largeEditions = [
			[ EDITIONS[ 0 ], EDITIONS[ 1 ], EDITIONS[ 2 ], EDITIONS[ 3 ] ],
			[ EDITIONS[ 4 ], EDITIONS[ 5 ], EDITIONS[ 6 ] ],
			[ EDITIONS[ 7 ], EDITIONS[ 8 ], EDITIONS[ 9 ] ],
		]

		let mediumEdtions = [
			[ EDITIONS[ 0 ], EDITIONS[ 1 ], EDITIONS[ 2 ], EDITIONS[ 3 ], EDITIONS[ 4 ] ],
			[ EDITIONS[ 5 ], EDITIONS[ 6 ], EDITIONS[ 7 ], EDITIONS[ 8 ], EDITIONS[ 9 ] ],
		]

		return (
			<div className={ this.props.classes.edition_container }>
				<Hidden only={ [ 'xs', 'sm', 'md' ] }>
					<List dense className={ this.props.classes.edition_list }>
						{
							largeEditions[ 0 ].map( ( e ) => {
								return this.renderEditionItem( e.name, e.site_id )
							} )
						}
					</List>

					<List dense className={ classNames( this.props.classes.edition_list, this.props.classes.edition_list_spacing ) }>
						{
							largeEditions[ 1 ].map( ( e ) => {
								return this.renderEditionItem( e.name, e.site_id )
							} )
						}
					</List>

					<List dense className={ classNames( this.props.classes.edition_list, this.props.classes.edition_list_spacing ) }>
						{
							largeEditions[ 2 ].map( ( e ) => {
								return this.renderEditionItem( e.name, e.site_id )
							} )
						}
					</List>
				</Hidden>

				<Hidden only={ [ 'xs', 'sm', 'lg', 'xl' ] }>
					<List dense className={ this.props.classes.edition_list }>
						{
							mediumEdtions[ 0 ].map( ( e ) => {
								return this.renderEditionItem( e.name, e.site_id )
							} )
						}
					</List>

					<List dense className={ classNames( this.props.classes.edition_list, this.props.classes.edition_list_spacing ) }>
						{
							mediumEdtions[ 1 ].map( ( e ) => {
								return this.renderEditionItem( e.name, e.site_id )
							} )
						}
					</List>
				</Hidden>

				<Hidden only={ [ 'md', 'lg', 'xl' ] }>
					<List dense className={ classNames( this.props.classes.edition_list, this.props.classes.edition_list_mobile ) }>
						{
							EDITIONS.map( ( e ) => {
								return this.renderEditionItem( e.name, e.site_id )
							} )
						}
					</List>
				</Hidden>
			</div>
		)
	}


	render() {
		return (
			<div className={ this.props.classes.outer_div }>
				<FormControl>
					<Select
						value={ this.props.currentLanguage }
						onChange={ this.handleLanguageChange }
						input={ <Input name="language" /> }
						className={ this.props.classes.language_select }
					>
						<MenuItem value="en">English</MenuItem>
						<MenuItem value="es">Spanish</MenuItem>
						<MenuItem value="pt">Portuguese</MenuItem>
					</Select>
					<FormHelperText>Language</FormHelperText>
				</FormControl>

				{ this.renderEditionSelectors() }
			</div>
		)
	}
}


EditionSelector.propTypes = {
	// component specific
	currentLanguage		: PropTypes.string.isRequired,
	currentEditions		: PropTypes.array.isRequired,
	updateLanguage		: PropTypes.func.isRequired,
	updateEditions		: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( EditionSelector )
