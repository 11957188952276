import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'

// import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CreateIcon from '@material-ui/icons/Create'
import ListIcon from '@material-ui/icons/List'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DraftsIcon from '@material-ui/icons/LibraryBooks'
import InfoIcon from '@material-ui/icons/Info'
// import ChatIcon from '@material-ui/icons/Chat'
// import LocalPlayIcon from '@material-ui/icons/LocalPlay'


const DRAWER_WIDTH = 240


const styles = ( theme ) => ( {
	toolbarIcon			: {
		display				: 'flex',
		alignItems			: 'center',
		justifyContent		: 'flex-end',
		padding				: '0 8px',
		height				: '64px'
	},
	drawer				: {
		height				: '100%',
	},
	drawerPaper			: {
		position			: 'relative',
		whiteSpace			: 'nowrap',
		width				: DRAWER_WIDTH,
		transition			: theme.transitions.create( 'width', {
			easing				: theme.transitions.easing.sharp,
			duration			: theme.transitions.duration.enteringScreen,
		} ),
		height				: '100%'
	},
	drawerPaperClose	: {
		overflowX			: 'hidden',
		transition			: theme.transitions.create( 'width', {
			easing				: theme.transitions.easing.sharp,
			duration			: theme.transitions.duration.leavingScreen,
		} ),
		width				: theme.spacing( 7 ),
		[ theme.breakpoints.up( 'sm' ) ]:	{
			width: theme.spacing( 9 ),
		},
		height				: '100%'
	},
} )


class AppDrawer extends React.Component {
	constructor( props ) {
		super( props )

		this.handleLink = this.handleLink.bind( this )
		this.isSelectedMenu = this.isSelectedMenu.bind( this )
	}

	handleLink( path ) {
		this.props.routeProps.history.push( path )
		this.props.closeDrawer()
	}

	isSelectedMenu( path ) {
		return this.props.routeProps.match.path === path
	}

	render() {
		const drawer = (
			<div>
				<div className={ this.props.classes.toolbarIcon }>
					<IconButton id="close-drawer" onClick={ this.props.closeDrawer }>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem selected={ this.isSelectedMenu( '/' ) } button onClick={ () => { this.handleLink( '/' ) } }>
						<Tooltip title="My Posts" enterDelay={ 500 } leaveDelay={ 200 }>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="My Posts" />
					</ListItem>
					{ /*
						Smile project
						<ListItem selected={ this.isSelectedMenu( '/smile' ) } button onClick={ () => { this.handleLink( '/smile' ) } }>
							<Tooltip title="Smile Uploads" enterDelay={ 500 } leaveDelay={ 200 }>
								<ListItemIcon>
									<PhotoAlbumIcon />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Smile Uploads" />
						</ListItem>
					*/ }
					<ListItem selected={ this.isSelectedMenu( '/compose' ) } button onClick={ () => { this.handleLink( '/compose' ) } }>
						<Tooltip title="Compose Post" enterDelay={ 500 } leaveDelay={ 200 }>
							<ListItemIcon>
								<CreateIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Compose Post" />
					</ListItem>
					<ListItem selected={ this.isSelectedMenu( '/drafts' ) } button onClick={ () => { this.handleLink( '/drafts' ) } }>
						<Tooltip title="Drafts" enterDelay={ 500 } leaveDelay={ 200 }>
							<ListItemIcon>
								<DraftsIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Drafts" />
					</ListItem>
					<Divider />
					{ /* <ListItem button onClick={ () => { this.handleLink( '/commentary' ) } }> */ }
					{ /*	<Tooltip title="Event Commentary" enterDelay={ 500 } leaveDelay={ 200 }> */ }
					{ /*		<ListItemIcon> */ }
					{ /*			<ChatIcon /> */ }
					{ /*		</ListItemIcon> */ }
					{ /*	</Tooltip> */ }
					{ /*	<ListItemText primary="Event Commentary" /> */ }
					{ /* </ListItem> */ }
					{ /* <ListItem button onClick={ () => { this.handleLink( '/events' ) } }> */ }
					{ /*	<Tooltip title="Past Events" enterDelay={ 500 } leaveDelay={ 200 }> */ }
					{ /*		<ListItemIcon> */ }
					{ /*			<LocalPlayIcon /> */ }
					{ /*		</ListItemIcon> */ }
					{ /*	</Tooltip> */ }
					{ /*	<ListItemText primary="Past Events" /> */ }
					{ /* </ListItem> */ }
					{ /* <Divider /> */ }
					<ListItem selected={ this.isSelectedMenu( '/settings' ) } button onClick={ () => { this.handleLink( '/settings' ) } }>
						<Tooltip title="Settings" enterDelay={ 500 } leaveDelay={ 200 }>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Settings" />
					</ListItem>
					<ListItem button onClick={ this.props.logOut }>
						<Tooltip title="Log Out" enterDelay={ 500 } leaveDelay={ 200 }>
							<ListItemIcon>
								<ExitToAppIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Log Out" />
					</ListItem>
					<ListItem button onClick={ this.props.openAbout }>
						<Tooltip title="About" enterDelay={ 500 } leaveDelay={ 200 }>
							<ListItemIcon>
								<InfoIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="About" />
					</ListItem>

				</List>
			</div>
		)

		return (
			<div>
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor={ this.props.theme.direction === 'rtl' ? 'right' : 'left' }
						open={ this.props.isOpen }
						onClose={ this.props.closeDrawer }
						classes={ {
							paper: classNames( this.props.classes.drawerPaper, !this.props.isOpen && this.props.classes.drawerPaperClose ),
						} }
						ModalProps={ {
							keepMounted: true, // Better open performance on mobile.
						} }
					>
						{ drawer }
					</Drawer>
				</Hidden>
				<Hidden smDown>
					<Drawer
						variant="permanent"
						open={ this.props.isOpen }
						className={ this.props.classes.drawer }
						classes={ {
							paper: classNames( this.props.classes.drawerPaper, !this.props.isOpen && this.props.classes.drawerPaperClose ),
						} }
					>
						{ drawer }
					</Drawer>
				</Hidden>
			</div>
		)
	}
}


AppDrawer.propTypes = {
	isOpen			: PropTypes.bool.isRequired,
	closeDrawer		: PropTypes.func.isRequired,
	logOut			: PropTypes.func.isRequired,
	openAbout		: PropTypes.func.isRequired,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
	theme			: PropTypes.object.isRequired,

	// provided by react router
	routeProps		: PropTypes.object.isRequired,
}


export default withStyles( styles, { withTheme: true } )( AppDrawer )
