import React from 'react'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import EditionSelector from '../common/edition_selector'
import helpers from '../../lib/helpers'


const styles = ( theme ) => ( {
	dialog_paper		: {
		minHeight			: '65%',
		minWidth			: '35%',
	},
	dialog_actions 				: {
		[theme.breakpoints.down( 'sm' )] : {
			marginBottom	: 40,
			marginRight		: 40
		}
	}
} )


class EditionDialog extends React.Component {
	constructor( props ) {
		super( props )

		this.updateLanguage = this.updateLanguage.bind( this )
		this.updateEditions = this.updateEditions.bind( this )

		this.cancelAssociation = this.cancelAssociation.bind( this )
		this.saveAssociation = this.saveAssociation.bind( this )

		this.state = {
			language	: props.currentLanguage,
			editions	: props.currentEditions,
		}
	}


	updateLanguage( newLanguage ) {
		let newState = _cloneDeep( this.state )
		newState.language = newLanguage

		this.setState( newState )
	}


	updateEditions( newEditions ) {
		let newState = _cloneDeep( this.state )
		newState.editions = newEditions

		this.setState( newState )
	}


	cancelAssociation() {
		this.props.closeDialog( this.props.currentLanguage, this.props.currentEditions, false )
	}


	saveAssociation() {
		this.props.closeDialog( this.state.language, this.state.editions, true )
	}


	render() {
		return (
			<Dialog
				disableBackdropClick
				open={ this.props.isOpen }
				onClose={ this.cancelAssociation }
				classes={ { paper: this.props.classes.dialog_paper } }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle>Language and Edition Settings</DialogTitle>
				<DialogContent>
					<EditionSelector
						currentLanguage={ this.state.language }
						currentEditions={ this.state.editions }
						updateLanguage={ this.updateLanguage }
						updateEditions={ this.updateEditions }
					/>
				</DialogContent>
				<DialogActions className={ this.props.classes.dialog_actions } >
					<Button variant="contained" onClick={ this.saveAssociation } color="primary">
						SAVE
					</Button>
					<Button variant="contained" onClick={ this.cancelAssociation } color="secondary">
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


EditionDialog.propTypes = {
	// component specific
	currentLanguage		: PropTypes.string.isRequired,
	currentEditions		: PropTypes.array.isRequired,
	isOpen				: PropTypes.bool.isRequired,
	closeDialog			: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( EditionDialog )
