import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'

import MenuIcon from '@material-ui/icons/Menu'

import UserAvatar from './common/user_avatar'


const DRAWER_WIDTH = 240

const styles = ( theme ) => ( {
	toolbar				: {
		paddingRight		: '24px', // keep right padding when drawer closed
	},
	appBar				: {
		zIndex				: theme.zIndex.drawer + 1,
		transition			: theme.transitions.create( [ 'width', 'margin' ], {
			easing				: theme.transitions.easing.sharp,
			duration			: theme.transitions.duration.leavingScreen,
		} ),
	},
	appBarShift			: {
		marginLeft			: DRAWER_WIDTH,
		width				: `calc(100% - ${ DRAWER_WIDTH }px)`,
		transition			: theme.transitions.create( [ 'width', 'margin' ], {
			easing				: theme.transitions.easing.sharp,
			duration			: theme.transitions.duration.enteringScreen,
		} ),
	},
	menuButton			: {
		marginLeft			: '-12px',
	},
	menuButtonHidden	: {
		display				: 'none',
	},
	logo				: {
		marginLeft			: '10px',
	},
	logoDrawerOpen		: {
		marginLeft			: '20px',
	},
	title				: {
		flexGrow			: 1,
		marginTop			: '5px',
		marginLeft			: '10px',
	},
	name				: {
		marginRight			: '10px',
	},
	drawerPaper			: {
		position			: 'relative',
		whiteSpace			: 'nowrap',
		width				: DRAWER_WIDTH,
		transition			: theme.transitions.create( 'width', {
			easing				: theme.transitions.easing.sharp,
			duration			: theme.transitions.duration.enteringScreen,
		} ),
	},
} )


class Header extends React.Component {
	render() {
		let logoClass = this.props.classes.logo
		if ( this.props.drawerOpen ) logoClass = this.props.classes.logoDrawerOpen

		return (
			<AppBar
				position="absolute"
				className={ classNames( this.props.classes.appBar, this.props.drawerOpen && this.props.classes.appBarShift ) }
			>
				<Toolbar disableGutters={ this.props.drawerOpen } className={ this.props.classes.toolbar }>
					<IconButton
						id="open-drawer"
						color="inherit"
						aria-label="Open drawer"
						onClick={ this.props.openDrawer }
						className={ classNames(
							this.props.classes.menuButton,
							this.props.drawerOpen && this.props.classes.menuButtonHidden,
						) }
					>
						<MenuIcon />
					</IconButton>
					<img className={ logoClass } src="https://secure.espncdn.com/redesign/assets/img/logos/logo-espn-82x20.png" alt="ESPN" />
					<Typography variant="h6" color="inherit" noWrap className={ this.props.classes.title }>
						Shortstop
					</Typography>
					<Hidden smDown implementation="css">
						<Typography variant="subtitle1" color="inherit" noWrap className={ this.props.classes.name }>
							{ this.props.currentUser.contact.name }
						</Typography>
					</Hidden>
					<UserAvatar currentUser={ this.props.currentUser } />
				</Toolbar>
			</AppBar>
		)
	}
}


Header.propTypes = {
	currentUser		: PropTypes.object.isRequired,

	// component specific
	drawerOpen		: PropTypes.bool.isRequired,
	openDrawer		: PropTypes.func.isRequired,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}


export default withStyles( styles )( Header )
