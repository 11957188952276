import React from 'react'
import PropTypes from 'prop-types'
import _cloneDeep from 'lodash/cloneDeep'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import PostPreview from '../common/post_preview'

import helpers from '../../lib/helpers'

const styles = () => ( {
	mobile_div: {
		padding: '16px'
	}
} )

class PostPreviewLayout extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			dialog : { open: false }
		}

		this.openDialog = this.openDialog.bind( this )
		this.closeDialog = this.closeDialog.bind( this )
	}

	openDialog() {
		let newState = _cloneDeep( this.state )
		newState.dialog.open = true

		this.setState( newState )
	}

	closeDialog() {
		let newState = _cloneDeep( this.state )
		newState.dialog.open = false

		this.setState( newState )
	}

	renderPostPreview() {
		return (
			<div>
				<PostPreview
					currentUser={ this.props.currentUser }
					post={ this.props.post }
					displayMenu={ false }
				/>
			</div>
		)
	}

	render() {
		let dialog = (
			<Dialog
				open={ this.state.dialog.open }
				onClose={ this.closeDialog }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle>Preview</DialogTitle>
				<DialogContent>
					{ this.renderPostPreview() }
				</DialogContent>
				<DialogActions>
					<Button onClick={ this.closeDialog } variant="contained">
                        Close
					</Button>
				</DialogActions>
			</Dialog>
		)

		let content = (
			<div className={ this.props.classes.mobile_div }>
				<Typography variant="body1" wrap="true">
					<Button
						fullWidth
						variant="outlined"
						color="secondary"
						component="span"
						onClick={ this.openDialog }
					>
                        Show preview
					</Button>
				</Typography>
				{ dialog }
			</div>
		)

		return (
			content
		)
	}
}

PostPreviewLayout.propTypes = {
	currentUser			: PropTypes.object.isRequired,

	// component specific
	post				: PropTypes.object.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( PostPreviewLayout )
