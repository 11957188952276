import User from '../models/user'
import Category from '../models/category'


const CURRENT_USER = 'CURRENT_USER'
const RECENT_CATEGORIES = 'RECENT_CATEGORIES'
const RECENT_EVENT = 'RECENT_EVENT'
const RECENT_DRAFT = 'RECENT_DRAFT'
const TIMESTAMP_WINDOW_CLOSED = 'TIMESTAMP_WINDOW_CLOSED'
const LAST_USER_ID = 'LAST_USER_ID'


class Storage {
	constructor( window ) {
		this._window = window
	}


	get currentUser() {
		let json = this._window.localStorage.getItem( CURRENT_USER )
		if ( !json ) return null

		return new User( JSON.parse( json ) )
	}


	set currentUser( user ) {
		if ( user === null ) return this._window.localStorage.removeItem( CURRENT_USER )

		let json = JSON.stringify( user )
		this._window.localStorage.setItem( CURRENT_USER, json )
	}


	get lastUserId() {
		let json = this._window.localStorage.getItem( LAST_USER_ID )
		if ( !json ) return null

		return JSON.parse( json ).user_id
	}


	set lastUserId( userId ) {
		if ( userId === null ) return this._window.localStorage.removeItem( LAST_USER_ID )

		let json = JSON.stringify( { user_id: userId } )
		this._window.localStorage.setItem( LAST_USER_ID, json )
	}


	get recentCategories() {
		let json = this._window.localStorage.getItem( RECENT_CATEGORIES )
		if ( !json ) return []

		let deserializedCategories = JSON.parse( json )
		let categories = deserializedCategories.map( ( c ) => { return new Category( c ) } )

		return categories
	}


	set recentCategories( categories ) {
		let json = JSON.stringify( categories )
		this._window.localStorage.setItem( RECENT_CATEGORIES, json )
	}


	get recentEvents() {
		let json = this._window.localStorage.getItem( RECENT_EVENT )
		if ( !json ) return []

		let deserializedEvents = JSON.parse( json )

		// check to see if the storage item is not an array; recent event launched with only support for one but we've moved to multiple
		if ( !Array.isArray( deserializedEvents ) ) {
			deserializedEvents = [ deserializedEvents ]
		}

		return deserializedEvents
	}


	set recentEvents( event ) {
		let json = JSON.stringify( event )
		this._window.localStorage.setItem( RECENT_EVENT, json )
	}

	get recentDrafts() {
		let json = this._window.localStorage.getItem( RECENT_DRAFT )
		if ( !json ) return []

		let deserializedDrafts = JSON.parse( json )

		return deserializedDrafts
	}

	set recentDrafts( drafts ) {
		let json = JSON.stringify( drafts )
		this._window.localStorage.setItem( RECENT_DRAFT, json )
	}

	get windowClosedTimeStamp() {
		let json = this._window.localStorage.getItem( TIMESTAMP_WINDOW_CLOSED )
		if ( !json ) return null

		let deserializedTime = JSON.parse( json )
		return deserializedTime
	}


	set windowClosedTimeStamp( time ) {
		let json = JSON.stringify( time )
		this._window.localStorage.setItem( TIMESTAMP_WINDOW_CLOSED, json )
	}
}


export default Storage
