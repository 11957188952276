export default class Commentary {
	static get friendlyName() { return 'Commentary' }
	static get modelName() { return Commentary.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Commentary.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }

	constructor( json = {} ) {
		this._id = json._id
		this.user = json.user
		this.reporter_event = json.reporter_event
		this.commentary_sequence = json.commentary_sequence
		this.commentary_text = json.commentary_text
		this.color_text = json.color_text
		this.created = json.created
	}

	get modelName() { return Commentary.modelName }
	get friendlyName() { return Commentary.friendlyName }
	get workItemType() { return Commentary.workItemType }
}
