/* eslint-disable camelcase */

let appVersion	= ( typeof ( kAPP_VERSION ) !== 'undefined' ) ? kAPP_VERSION : '0.0.0'
let userId = ( typeof ( kDEVELOPMENT_USER_ID ) !== 'undefined' ) ? kDEVELOPMENT_USER_ID : null
let accessToken = ( typeof ( kDEVELOPMENT_ACCESS_TOKEN ) !== 'undefined' ) ? kDEVELOPMENT_ACCESS_TOKEN : null
let lastCommitSha	= ( typeof ( kLAST_COMMIT_SHA ) !== 'undefined' ) ? kLAST_COMMIT_SHA : 'xxxxxx'
let lastCommitDesc	= ( typeof ( kLAST_COMMIT_DESC ) !== 'undefined' ) ? kLAST_COMMIT_DESC : 'UNKNOWN'

/* eslint-enable camelcase */


let apiServers = {
	local				: 'https://mex.dev:9283',
	development		: 'https://api.shortstop.espnsb.com',
	qa					: 'https://api.shortstop.espnqa.com',
	production		: 'https://api.shortstop.espn.com'
}


let clientHosts = {
	local				: 'https://mexui.dev:9283',
	development		: 'https://client.shortstop.espnsb.com',
	qa					: 'https://client.shortstop.espnqa.com',
	production		: 'https://client.shortstop.espn.com'
}


export default class config {
	static get environment() {
		return process.env.NODE_ENV || 'test'
	}


	static get apiHost() {
		return apiServers[ config.environment ]
	}


	static get clientHost() {
		return clientHosts[ config.environment ]
	}


	static get oidClientId() {
		return 'ESPN-SHORTSTOP.IOS-PROD'
	}


	static get idleTimeoutInMS() {
		return 20 * 60 * 1000 // 20 minutes
	}


	static get appVersion() {
		return appVersion
	}


	static get userId() {
		return userId
	}


	static get accessToken() {
		return accessToken
	}


	static get lastCommitSha() {
		return lastCommitSha
	}


	static get lastCommitDesc() {
		return lastCommitDesc
	}
}
