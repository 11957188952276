import React from 'react'
import PropTypes from 'prop-types'

import _concat from 'lodash/concat'
import _cloneDeep from 'lodash/cloneDeep'

import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import Timeline from '../timeline/timeline_layout'
import InfiniteScroll from '../common/infinite_scroll'

import API from '../../lib/api'
import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'


const NUM_POSTS_PER_FETCH = 25


const styles = ( theme ) => ( {
	empty_container		: {
		marginTop			: '7%',
		textAlign			: 'center',
	},
	empty_icon			: {
		fontSize			: 60,
		paddingBottom		: 15,
		fontFamily			: 'ESPNIcons',
	},
	empty_headline		: {

	},
	empty_caption		: {
		fontSize			: 15,
	},
	delete_list			: {
		marginTop			: 10,
	},
	dialog_actions 				: {
		[theme.breakpoints.down( 'sm' )] : {
			marginBottom	: 40,
			marginRight		: 40
		}
	}
} )


class MyPosts extends React.Component {
	constructor( props ) {
		super( props )

		this.API = API
		if ( props.api ) this.API = props.api

		this.state = this.initialState

		this.editPost = this.editPost.bind( this )
		this.sharePost = this.sharePost.bind( this )
		this.displayDeleteConfirmation = this.displayDeleteConfirmation.bind( this )
		this.deletePost = this.deletePost.bind( this )
		this.cancelDelete = this.cancelDelete.bind( this )
	}


	async componentDidMount() {
		await this.fetchPosts( this.state.current_page )
		if ( this.props.mounted ) this.props.mounted()
	}


	get initialState() {
		return { timeline: { data: [], total: 0 }, current_page: 0, postToDelete: null, showDeleteDialog: false, loading: false, hasMore: true }
	}


	reloadTimeline() {
		this.setState( this.initialState, async () => {
			await this.fetchPosts( this.state.current_page )
		} )
	}


	async fetchPosts( pageNumber ) {
		try {
			if ( this.state.loading ) return false

			this.setState( { loading: true }, async () => {
				this.props.showProgressDialog( 'Timeline', 'Fetching posts' )
				let posts = await this.API.Media.searchMedia( this.props.currentUser, NUM_POSTS_PER_FETCH, NUM_POSTS_PER_FETCH * pageNumber )
				this.props.closeProgressDialog()
	
				const filteredData = posts.data.filter( post => helpers.doesNotExist( post.smile_content ) || post.smile_content === false )
	
				let clonedTimelineData = _cloneDeep( this.state.timeline.data )
				let newTimelineData = _concat( clonedTimelineData, filteredData )

				this.setState( {
					loading: false,
					timeline: { data: newTimelineData, total: posts.total },
					current_page: pageNumber + 1,
					hasMore: posts.data.length === NUM_POSTS_PER_FETCH
				} )
			} )
		}
		catch ( e ) {
			this.props.closeProgressDialog()
			this.props.showErrorDialog( 'Timeline', e )
		}
	}


	displayDeleteConfirmation( post ) {
		Analytics.recordUserActivity()
		this.setState( { postToDelete: post, showDeleteDialog: true } )
	}


	cancelDelete() {
		Analytics.recordUserActivity()
		this.setState( { postToDelete: null, showDeleteDialog: false } )
	}


	deletePost() {
		Analytics.recordUserActivity()

		this.setState( { showDeleteDialog: false }, async () => {
			try {
				this.props.showProgressDialog( 'Timeline', 'Deleting post' )
				await this.API.Media.deleteMedia( this.props.currentUser, this.state.postToDelete._id )
				this.props.closeProgressDialog()

				this.reloadTimeline()
			}
			catch ( e ) {
				this.props.closeProgressDialog()
				this.props.showErrorDialog( 'Timeline', e )
			}
		} )
	}


	editPost( post ) {
		this.props.routeProps.history.push( `/edit/${ post._id }` )
	}


	sharePost( post ) {
		Analytics.recordUserActivity()

		window.FB.ui( {
			method	: 'share',
			href	: post.shortstop.now_url,
		}, ( response ) => {

		} )
	}


	renderEmptyTimeline() {
		return (
			<div className={ this.props.classes.empty_container }>
				<div className={ this.props.classes.empty_icon }>&#57456;</div>

				<Typography variant="subtitle1" color="inherit" noWrap className={ this.props.classes.empty_headline }>
					You haven&#39;t written any posts yet!
				</Typography>

				<br />

				<Typography variant="caption" color="inherit" noWrap className={ this.props.classes.empty_caption }>
					Go to the <Link to="/compose" style={ { textDecoration: 'none' } }>Create</Link> screen to get started
				</Typography>
			</div>
		)
	}


	render() {
		if ( this.state.timeline.total === 0 ) return this.renderEmptyTimeline()

		let deleteConfirmation = ( <div /> )
		if ( this.state.showDeleteDialog ) {
			let postText = this.state.postToDelete.shortstop.body_text
			if ( postText.length > 50 ) postText = `${ postText.substring( 0, 49 ) }...`

			deleteConfirmation = (
				<Dialog open={ this.state.showDeleteDialog } fullScreen={ helpers.isMobile() }>
					<DialogTitle>Delete Post</DialogTitle>

					<DialogContent>
						<DialogContentText>
							Please confirm you wish to delete the post &quot;{ postText }&quot;.  This will result in the following actions:
						</DialogContentText>
						<List className={ this.props.classes.delete_list }>
							<ListItem dense>
								<ListItemIcon><RemoveCircleIcon /></ListItemIcon>
								<ListItemText primary="ESPN Now Feed" secondary="Post will be removed" />
							</ListItem>

							<ListItem dense>
								<ListItemIcon><RemoveCircleIcon /></ListItemIcon>
								<ListItemText primary="Social Accounts" secondary="Any social networks that this post was cross published to will be removed" />
							</ListItem>
						</List>
					</DialogContent>

					<DialogActions className={ this.props.classes.dialog_actions }>
						<Button onClick={ this.deletePost } color="primary" variant="contained">
							Ok
						</Button>
						<Button onClick={ this.cancelDelete } color="secondary" variant="contained">
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			)
		}

		return (
			<div>
				<InfiniteScroll loadMore={ () => this.fetchPosts( this.state.current_page ) } hasMore={ this.state.hasMore }>
					<Timeline
						currentUser={ this.props.currentUser }
						timeline={ this.state.timeline }
						deletePost={ this.displayDeleteConfirmation }
						sharePost={ this.sharePost }
						editPost={ this.editPost }
					/>
				</InfiniteScroll>
				{ deleteConfirmation }
			</div>
		)
	}
}


MyPosts.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	showErrorDialog		: PropTypes.func.isRequired,
	showProgressDialog	: PropTypes.func.isRequired,
	closeProgressDialog	: PropTypes.func.isRequired,

	// test hooks
	api					: PropTypes.object,
	mounted				: PropTypes.func,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// provided by react router
	routeProps		: PropTypes.object.isRequired,
}


export default withStyles( styles )( MyPosts )
