import helpers from '../lib/helpers'

export default class UserMetadata {
	static get friendlyName() { return 'UserMetadata' }
	static get modelName() { return UserMetadata.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return UserMetadata.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }

	constructor( json = {} ) {
		this.user = json.user
		this.created = json.created

		if ( helpers.doesExist( json.web_client ) ) this.web_client = json.web_client
		if ( helpers.doesExist( json.admin ) ) this.admin = json.admin
		if ( helpers.doesExist( json.shortstop_ios ) ) this.shortstop_ios = json.shortstop_ios
	}

	get modelName() { return UserMetadata.modelName }
	get friendlyName() { return UserMetadata.friendlyName }
	get workItemType() { return UserMetadata.workItemType }
}
