import React from 'react'
import PropTypes from 'prop-types'
import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Edit from '@material-ui/icons/Edit'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Button from '@material-ui/core/Button'

import CategorySearch from '../common/category_search'
import SelectedCategories from '../common/selected_categories'
import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import API from '../../lib/api'


const styles = ( theme ) => ( {
	categories_label	: {
		marginTop			: 20
	},
	edit_div			: {
		display		: 'flex',
		justifyContent: 'flex-end'
	},
	edit_button			: {
		boxShadow		: 'none'
	},
	mobile_div			: {
		display		: 'flex',
		alignItems	: 'center',
		width		: '100%'
	},
	button_class		: {
		display			: 'flex',
		justifyContent	: 'center',
		marginTop		: 50
	}
} )


class CategoryLayout extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			categories_search	: { current_search_term: '', type: 'any', searching: false, results: { categories: [], error: null } },
			dialog : { open: false }
		}

		this.API = API
		if ( props.api ) this.API = props.api

		this.updateCurrentSearchTerm = this.updateCurrentSearchTerm.bind( this )
		this.updateCategoryType = this.updateCategoryType.bind( this )
		this.categorySelected = this.categorySelected.bind( this )
		this.categoryRemoved = this.categoryRemoved.bind( this )
		this.openDialog = this.openDialog.bind( this )
		this.closeDialog = this.closeDialog.bind( this )
	}


	updateCurrentSearchTerm( newSearchTerm ) {
		Analytics.recordUserActivity()

		if ( newSearchTerm === '' ) return this.resetSearch()

		let newState = _cloneDeep( this.state )
		newState.categories_search.current_search_term = newSearchTerm

		let newCategoryState = newState.categories_search.results.categories
		if ( newSearchTerm.length < 3 ) newCategoryState = []
		newState.categories_search.results = { error: null, categories: newCategoryState }

		this.setState( newState, () => {
			if ( newState.categories_search.current_search_term.length >= 3 ) {
				this.searchCategories( newState.categories_search.current_search_term, newState.categories_search.type )
			}
			else {
				if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
			}
		} )
	}


	updateCategoryType( categoryType ) {
		Analytics.recordUserActivity()

		let newState = _cloneDeep( this.state )
		newState.categories_search.type = categoryType
		this.setState( newState )
	}


	categorySelected( category ) {
		let categories = ( this.props.post.shortstop ) ? this.props.post.shortstop.categories : this.props.currentUser.shortstop.default_categories

		// don't allow the user to add the same category twice
		let existingCategory = categories.find( ( c ) => { return c.category_id === category.category_id && c.type === category.type && c.context.sport === category.context.sport } )

		if ( existingCategory !== undefined ) {
			return this.resetSearch()
		}

		if ( this.props.post.shortstop ) {
			let newPost = _cloneDeep( this.props.post )
			newPost.shortstop.categories.push( category )

			this.props.updatePostState( newPost, () => {
				this.resetSearch()
			} )
		}
		else {
			let newUser = _cloneDeep( this.props.currentUser )
			newUser.shortstop.default_categories.push( category )

			this.props.updateCurrentUser( newUser )
			this.resetSearch()
		}
	}


	categoryRemoved( category ) {
		Analytics.recordUserActivity()

		if ( this.props.post.shortstop ) {
			let newPost = _cloneDeep( this.props.post )
			newPost.shortstop.categories.splice( newPost.shortstop.categories.findIndex( ( c ) => { return c.category_id === category.category_id && c.type === category.type && c.context.sport === category.context.sport } ), 1 )

			this.props.updatePostState( newPost, () => { this.resetSearch() } )
		}
		else {
			let newUser = _cloneDeep( this.props.currentUser )
			newUser.shortstop.default_categories.splice( newUser.shortstop.default_categories.findIndex( ( c ) => { return c.category_id === category.category_id && c.type === category.type && c.context.sport === category.context.sport } ), 1 )

			this.props.updateCurrentUser( newUser )
			this.resetSearch()
		}
	}


	resetSearch() {
		let newState = _cloneDeep( this.state )
		newState.categories_search = { current_search_term: '', type: this.state.categories_search.type, searching: false, results: { categories: [], error: null } }

		this.setState( newState )
	}


	searchCategories( searchTerm, categoryType ) {
		if ( searchTerm === '' ) return

		let newState = _cloneDeep( this.state )
		newState.categories_search.searching = true

		let searchGuruCategoryType
		if ( categoryType !== 'any' ) searchGuruCategoryType = categoryType

		this.setState( newState, () => {
			this.API.Categories.searchCategories( this.props.currentUser, searchTerm, searchGuruCategoryType )
				.then( ( searchResults ) => {
					if ( searchResults.searchTerm !== this.state.categories_search.current_search_term ) return

					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: searchResults.models, error: null }

					this.setState( newState, () => {
						if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
					} )
				} )
				.catch( ( err ) => {
					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: [], error: err }

					this.setState( newState, () => {
						if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
					} )
				} )
		} )
	}


	openDialog() {
		let newState = _cloneDeep( this.state )
		newState.dialog.open = true

		this.setState( newState )
	}


	closeDialog() {
		let newState = _cloneDeep( this.state )
		newState.dialog.open = false

		this.setState( newState )
	}


	renderCategoryContent() {
		return (
			<div>
				<CategorySearch
					displayTypeSelector
					clearOnSelection
					placeholderText={ 'Search Categories' }
					searchTerm={ this.state.categories_search.current_search_term }
					categoryType={ this.state.categories_search.type }
					searching={ this.state.categories_search.searching }
					searchResults={ this.state.categories_search.results }
					updateSearchTerm={ this.updateCurrentSearchTerm }
					updateCategoryType={ this.updateCategoryType }
					categorySelected={ this.categorySelected }
				/>

				<Typography variant="caption" color="textSecondary" noWrap className={ this.props.classes.categories_label }>
					SELECTED CATEGORIES
				</Typography>

				<SelectedCategories
					selectedCategories={ this.props.post.shortstop ? this.props.post.shortstop.categories : this.props.currentUser.shortstop.default_categories }
					categoryRemoved={ this.categoryRemoved }
				/>
			</div>
		)
	}


	render() {
		let content = ( <div /> )

		let dialog = (
			<Dialog
				open={ this.state.dialog.open }
				onClose={ this.closeDialog }
				fullScreen={ helpers.isMobile() }
			>
				<DialogTitle>Manage Categories</DialogTitle>
				<DialogContent>
					{ this.renderCategoryContent() }
					<div className={ this.props.classes.button_class }>
						<Button onClick={ this.closeDialog } color="primary" variant="contained">
							DONE
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		)

		if ( helpers.isMobile() ) {
			let categories = ( this.props.post.shortstop ) ? this.props.post.shortstop.categories : this.props.currentUser.shortstop.default_categories

			content = (
				<div className={ this.props.classes.mobile_div }>
					<Typography variant="body1" color="textSecondary" wrap="true" className={ this.props.classes.mobile_div }>
						<span style={ { width: '100%' } }>
							<b>Categories:</b>
							{
								categories.length > 0 ? categories.map( ( category, index ) => {
									if ( index === categories.length - 1 ) {
										return (
											` ${ category.name }`
										)
									}
									else {
										return (
											` ${ category.name },`
										)
									}
								} ) : ' None'
							}
						</span>

						<span className={ this.props.classes.edit_div }>
							<Fab color="secondary" aria-label="Edit" className={ this.props.classes.edit_button } size="small" onClick={ this.openDialog }>
								<Edit />
							</Fab>
						</span>
					</Typography>
					{ dialog }
				</div>
			)
		}
		else {
			content = this.renderCategoryContent()
		}

		return (
			content
		)
	}
}

CategoryLayout.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	updatePostState		: PropTypes.func.isRequired,
	updateCurrentUser	: PropTypes.func.isRequired,

	// component specific
	post				: PropTypes.object.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// test hooks
	api					: PropTypes.object,
	searchComplete		: PropTypes.func,
}


export default withStyles( styles )( CategoryLayout )
