import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import _get from 'lodash/get'
import grey from '@material-ui/core/colors/grey'
import Typography from '@material-ui/core/Typography'

import helpers from '../../lib/helpers'

const useStyles = makeStyles( {
	root: {
		maxWidth: 320,
		minWidth: 320,
		marginBottom: 10
	},
	url: {
		fontSize: '0.8em'
	}
} )

const ImagePreview = ( { media, imageIndex } ) => {
	const classes = useStyles()
    
	let resizedPhoto

	if ( Array.isArray( media ) ) {
		const photo = media.find( e => e.kind === 'photo' )
		resizedPhoto = photo.formats.find( format => format.display_type === 'resized' )

		if ( helpers.doesNotExist( resizedPhoto ) ) {
			resizedPhoto = media.find( e => e.kind === 'photo' ).formats[0]
		}
	}
	else {
		resizedPhoto = media.formats.find( format => format.display_type === 'resized' )

		if ( helpers.doesNotExist( resizedPhoto ) ) {
			resizedPhoto = media.formats[0]
		}
	}

	let imageStyle = { width: '100%', minWidth: '100%', maxWidth: '100%', display: 'block', margin: '0 auto' }
	let cardMediaStyle = {}
	let orientation = _get( resizedPhoto, 'metadata.orientation_flag', 'landscape' )

	if ( orientation === 'portrait' ) {
		imageStyle = { width: '31%', minWidth: '31%', maxWidth: '31%', display: 'block', margin: '0 auto' }
		cardMediaStyle = { backgroundColor: grey[ 200 ] }
	}

	return (
		<Card className={ classes.root }>
			<div style={ cardMediaStyle }>
				<img src={ resizedPhoto.url } style={ imageStyle } />
			</div>
			<CardContent>
				<Typography className={ classes.url } component="p">
					{ `(${ imageIndex })` }
				</Typography>
			</CardContent>
		</Card>
	)
}

ImagePreview.propTypes = {
	media: PropTypes.oneOfType( [
		PropTypes.array,
		PropTypes.object
	] ).isRequired,
	imageIndex: PropTypes.number
}

export default ImagePreview
