import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import Event from '../../models/event'

import BaseAPI from './_api'


export default class EventsAPI {
	static searchEvents( currentUser, teamUid, startDate ) {
		return new Promise( ( resolve, reject ) => {
			let queryParams = { team_uid: teamUid, start_date: startDate }

			let path = `${ config.apiHost }/api/v1/events`
			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Search ${ Event.friendlyName }` )

					let events = res.body.map( ( item ) => {
						return new Event( item )
					} )

					resolve( events )
				} )
		} )
	}
}
