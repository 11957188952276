import React from 'react'
import PropTypes from 'prop-types'

import _isObject from 'lodash/isObject'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import ErrorIcon from '@material-ui/icons/Error'

import red from '@material-ui/core/colors/red'

import config from '../../lib/config'


const styles = ( theme ) => ( {
	content			: {
		display			: 'flex',
	},
	error_icon		: {
		alignSelf		: 'center',
		marginRight		: '10px',
		fontSize		: 50,
		color			: red[ 900 ],
	},
	error_text		: {
		alignSelf		: 'center',
	},
	details			: {
		marginTop		: '10px',
	},
	details_header	: {
		fontSize		: theme.typography.pxToRem( 15 ),
		fontWeight		: theme.typography.fontWeightRegular,
	},
} )


class ErrorDialog extends React.Component {
	render() {
		if ( !this.props.isOpen ) return ( <div /> )

		if ( config.environment !== 'test' ) {
			console.log( this.props.error )	// eslint-disable-line no-console
		}

		let errorMessage = this.props.error.message
		if ( _isObject( errorMessage ) ) {
			if ( errorMessage.invalid_values !== undefined ) {
				errorMessage = `param: ${ errorMessage.invalid_values[ 0 ].param } message: ${ errorMessage.invalid_values[ 0 ].message }`
			}
			else {
				try {
					errorMessage = JSON.stringify( errorMessage )
				}
				catch ( e2 ) {
					// DO NOTHING
				}
			}
		}

		return (
			<Dialog
				open={ this.props.isOpen }
				onClose={ this.props.closeDialog }
			>
				<DialogTitle>{ this.props.dialogTitle }</DialogTitle>
				<DialogContent>
					<div className={ this.props.classes.content }>
						<ErrorIcon className={ this.props.classes.error_icon } />
						<div className={ this.props.classes.error_text }>
							<DialogContentText>
								{ errorMessage }
							</DialogContentText>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={ this.props.closeDialog } color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


ErrorDialog.propTypes = {
	isOpen			: PropTypes.bool.isRequired,
	dialogTitle		: PropTypes.string.isRequired,
	error			: PropTypes.object.isRequired,
	closeDialog		: PropTypes.func.isRequired,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}


export default withStyles( styles )( ErrorDialog )
