import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import ScribeColumnist from '../../models/scribe_columnist'

import BaseAPI from './_api'


export default class ScribeAPI {
	static getScribeColumnist( currentUser, columnistId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/scribe/columnist/${ columnistId }`
			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Update ${ ScribeColumnist.friendlyName }` )
					resolve( new ScribeColumnist( res.body ) )
				} )
		} )
	}
}
