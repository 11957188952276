import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import User from '../../models/user'

import BaseAPI from './_api'


export default class AuthAPI {
	static loginWithOneId( email, swid, accessToken ) {
		return new Promise( ( resolve, reject ) => {
			request.post( `${ config.apiHost }/api/v1/auth/one_id` )
				.query( { is_shortstop_login: true } )
				.type( 'application/json' )
				.accept( 'json' )
				.send( { access_token: accessToken, swid: swid, email: email, did_client_id: config.oidClientId } )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Login' )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static getTwitterAuthEndpoint( currentUser, twitterCallback ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/auth/twitter/auth-endpoint?callback_url=${ twitterCallback }`
			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Get Twitter Auth Endpoint' )

					resolve( res.body )
				} )
		} )
	}


	static getTwitterOAuthToken( currentUser, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/auth/twitter/oauth-token`
			BaseAPI.setupRequest( currentUser, request.post( path ) )
				.send( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Get Twitter OAuth Token' )

					resolve( res.body )
				} )
		} )
	}


	static addTwitterSocialTokensToUser( currentUser, userId, params = {} ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ userId }/twitter`
			BaseAPI.setupRequest( currentUser, request.put( path ) )
				.send( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Add Twitter to ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static removeTwitterSocialTokensFromUser( currentUser, userId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v1/users/${ userId }/twitter`
			BaseAPI.setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, `Remove Twitter from ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}
}
