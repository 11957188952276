import request from 'superagent'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import BaseAPI from './_api'


export default class SiteAPI {
	static getSiteApiEvent( currentUser, eventId, language ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ config.apiHost }/api/v2/siteApi/events?eventId=${ eventId }&language=${ language }`

			BaseAPI.setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return BaseAPI.handleError( err, res, reject, 'Get Site API Event' )

					resolve( res.body )
				} )
		} )
	}
}
