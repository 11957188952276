import _get from 'lodash/get'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'

import API from '../../lib/api'
import helpers from '../../lib/helpers'

const AVATAR_LOADING = 'loading'
const AVATAR_LOADED = 'loaded'
const AVATAR_UNAVAILABLE = 'unavailable'


const styles = ( theme ) => ( {
	avatar			: {
		border			: '1px solid #BDBDBD',
		objectFit		: 'cover',
		backgroundColor	: theme.palette.primary,
	},
	loaded_avatar	: {
		border			: '1px solid #BDBDBD',
		objectFit		: 'cover',
		backgroundColor	: '#fafafa',
	},
	avatar_card		: {
		marginRight		: 16,
	},
} )


class UserAvatar extends React.Component {
	constructor( props ) {
		super( props )

		this.API = API
		if ( props.api ) this.API = props.api

		this.state = { avatar: AVATAR_LOADING }

		this.avatarImage = new window.Image()

		this.avatarImage.onload = () => { this.setState( { avatar: AVATAR_LOADED } ) }
		this.avatarImage.onerror = ( err ) => { this.setState( { avatar: AVATAR_UNAVAILABLE } ) }	// eslint-disable-line handle-callback-err
	}


	async componentDidMount() {
		await this.loadAvatarImage()
		if ( this.props.mounted ) this.props.mounted()
	}


	async loadAvatarImage() {
		try {
			let columnistId = _get( this.props, 'currentUser.shortstop.columnist_id', null )
			if ( columnistId !== null ) {
				let scribeColumnistInfo = await this.API.Scribe.getScribeColumnist( this.props.currentUser, columnistId )
				this.avatarImage.src = scribeColumnistInfo.image_url.replace( 'http://a.espncdn.com', 'https://secure.espncdn.com' )
			}
		}
		catch ( e ) {
			if ( process.env.NODE_ENV !== 'test' ) console.log( e )	// eslint-disable-line no-console
			this.setState( { avatar: AVATAR_UNAVAILABLE } )
		}
	}


	render() {
		let className = this.props.classes.avatar

		if ( this.state.avatar === AVATAR_LOADED ) {
			className = this.props.classes.loaded_avatar
		}

		if ( this.props.cardAvatar ) {
			className = classNames( className, this.props.classes.avatar_card )
		}

		if ( this.state.avatar === AVATAR_LOADING ) {
			return ( <Avatar className={ className } /> )
		}
		else if ( this.state.avatar === AVATAR_LOADED ) {
			let avatarUrl = this.avatarImage.src
			return ( <Avatar className={ className } src={ avatarUrl } /> )
		}

		let avatarLetters = ''
		let name = _get( this.props, 'currentUser.contact.name' )

		if ( helpers.doesExist( name ) ) {
			avatarLetters = name.substring( 0, 1 )

			let nameParts = name.split( ' ' )
			if ( nameParts.length === 1 ) {
				avatarLetters = nameParts[ 0 ].substring( 0, 1 )
			}
			else if ( nameParts.length >= 1 ) {
				avatarLetters = `${ nameParts[ 0 ].substring( 0, 1 ) }${ nameParts[ 1 ].substring( 0, 1 ) }`
			}
		}

		return ( <Avatar className={ className }>{ avatarLetters }</Avatar> )
	}
}


UserAvatar.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	cardAvatar			: PropTypes.bool,

	// test hooks
	api					: PropTypes.object,
	mounted				: PropTypes.func,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,
}


export default withStyles( styles )( UserAvatar )
