export default class Helpers {
	static isNotEmpty( value ) {
		return ( value !== null && value !== undefined && value.length > 0 )
	}

	static doesExist( value ) {
		return ( value !== null && value !== undefined )
	}

	static doesNotExist( value ) {
		return Helpers.doesExist( value ) === false
	}


	static isTrue( value ) {
		if ( Helpers.doesNotExist( value ) ) return false

		if ( value === true ) {
			return true
		}

		if ( value === 'true' ) {
			return true
		}

		if ( value === 1 ) {
			return true
		}

		if ( value === '1' ) {
			return true
		}

		if ( value === 'on' ) {
			return true
		}

		if ( value === 'yes' ) {
			return true
		}

		return false
	}


	static getSecureCDNLink( httpUrl ) {
		return httpUrl.replace( 'http://a.espncdn.com', 'https://secure.espncdn.com' )
	}


	static isMobile() {
		let userAgent = window.navigator.userAgent

		if ( userAgent.match( /Android/i ) ||
			userAgent.match( /webOS/i ) ||
			userAgent.match( /iPhone/i ) ||
			userAgent.match( /iPad/i ) ||
			userAgent.match( /iPod/i ) ||
			userAgent.match( /BlackBerry/i ) ||
			userAgent.match( /Windows Phone/i ) ) {
			return true
		}
		else {
			return false
		}
	}

	static isValidAssetType( type ) {
		return type.includes( 'image' ) ? type.includes( 'png' ) || type.includes( 'jpg' ) || type.includes( 'jpeg' ) : type.includes( 'mov' ) || type.includes( 'mp4' )
	}

	static getLastUrlSegment( url ) {
		return url.split( '/' ).pop()
	}
}
