import React from 'react'
import PropTypes from 'prop-types'

import QS from 'query-string'
import _cloneDeep from 'lodash/cloneDeep'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import Media from '../../models/media'

import LocalStorage from '../../lib/local_storage'

import API from '../../lib/api'
import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import PostPreview from '../common/post_preview'
import PostPreviewLayout from '../preview/preview_layout'
import Compose from '../compose/compose_layout'
import EditTweet from '../common/edit_tweet'
import ImagePreview from '../common/image_preview'


const styles = ( theme ) => ( {
	div				: {
		[theme.breakpoints.down( 'sm' )] : {
			height : '100%'
		}
	},
	container		: {
		display			: 'flex',
		flexWrap		: 'wrap',
		[theme.breakpoints.down( 'sm' )] : {
			marginTop : 0
		}
	},
	column			: {
		flex			: 1,
		overflow		: 'auto',
		width			: '45%',
		marginRight		: '5%',
		marginTop		: 15,
		[theme.breakpoints.down( 'sm' )] : {
			marginRight : 0
		}
	},
	mobilePreview : {
		[theme.breakpoints.down( 'sm' )] : {
			display: 'none'
		}
	},
	showPreviewButton : {
		[theme.breakpoints.up( 'md' )] : {
			display: 'none'
		}
	},
	testMode : {
		marginLeft 		: 12,
		display			: 'inline-block',
		verticalAlign	: 'top',
		[theme.breakpoints.down( 'sm' )] : {
			width		: '100%'
		}
	},
	paper : {
		[theme.breakpoints.up( 'sm' )] : {
			width: '100%'
		},
		[theme.breakpoints.down( 'sm' )] : {
			height: '100%'
		}
	},
	card_content : {
		paddingBottom: 0
	},
	publish_progress		: {
		position			: 'absolute',
		marginLeft			: 10,
		top					: 5
	},
	container_button		: {
		marginBottom		: 15,
		marginTop			: 15,
		marginRight			: 30,
		width				: 132,
		[theme.breakpoints.down( 'sm' )] : {
			width			: '55%',
			marginRight		: 0
		}
	},
	container_button_wrapper: {
		display				: 'flex',
		alignItems			: 'center',
		justifyContent		: 'center',
		marginLeft			: 15,
		marginRight			: 15,
		[theme.breakpoints.down( 'sm' )] : {
			flexDirection	: 'column',
			justifyContent	: 'center'

		}
	},
	cancel_button		: {
		width				: '48%',
		marginRight			: 10,
		marginLeft			: 10,
		marginBottom		: 15,
		marginTop			: 15,
	},
	save_button			: {
		width				: 182,
		float				: 'right',
		marginBottom		: 15,
		marginTop			: 15,
	}
} )


class Create extends React.Component {
	constructor( props ) {
		super( props )

		this.API = API
		if ( props.api ) this.API = props.api

		let initialPost = null
		if ( this.isNewPost ) initialPost = Media.createEmptyMedia( this.props.currentUser )

		this.state = {
			post			: initialPost,
			publishing		: false,
			uploading		: false,
			edit_tweet_dialog : {
				open : false
			}
		}

		this._storage = new LocalStorage( window )

		this.updatePostState = this.updatePostState.bind( this )
		this.updateUploadState = this.updateUploadState.bind( this )
		this.publishPost = this.publishPost.bind( this )
		this.startPublish = this.startPublish.bind( this )
		this.cancelEditing = this.cancelEditing.bind( this )
		this.isValidDraft = this.isValidDraft.bind( this )

		this.saveDraft = this.saveDraft.bind( this )
		this.toggleTestPost = this.toggleTestPost.bind( this )
		this.openEditTweet = this.openEditTweet.bind( this )
		this.closeEditTweet = this.closeEditTweet.bind( this )
	}


	get storage() { return this._storage }

	get isNewPost() {
		return this.props.routeProps.match.params.post_id === undefined
	}


	async componentDidMount() {
		try {
			if ( this.props.routeProps.location !== undefined ) {
				const draftId = this.getDraftIdFromQueryString( this.props.routeProps.location.search )
				let recentDrafts = this.storage.recentDrafts

				if ( draftId ) {
					const loadedDraftSuccess = await this.loadDraft( draftId, recentDrafts )
					if ( loadedDraftSuccess ) return
				}
			}
		}
		catch ( e ) {
			this.props.showErrorDialog( 'Loading a draft from ID', e )
		}

		if ( this.isNewPost ) return

		try {
			await this.getPost()
		}
		catch ( e ) {
			this.props.showErrorDialog( 'Edit Post', e )
		}
	}


	loadDraft( draftId, recentDrafts ) {
		return new Promise( ( resolve, reject ) => {
			try {
				const foundIndex = recentDrafts.findIndex( draft => draft.created === draftId )

				if ( foundIndex !== -1 ) {
					let initialPost = Media.createEmptyMedia( this.props.currentUser )
					let draftPost = recentDrafts[foundIndex]

					return this.setState( { post : Object.assign( initialPost, draftPost ) }, () => { resolve( true ) } )
				}
				// TODO: add a case for when the draft is not found that is not just simply skipping.

				resolve( false ) // Until the TODO is done, just resolve false if the above does not load a draft.
			}
			catch ( e ) {
				reject( e )
			}
		} )
	}

	removeDraftIdFromState() {
		return new Promise( ( resolve, reject ) => {
			this.setState( prevState => {
				return { post: Object.assign( prevState.post, { created: undefined } ) }
			}, () => resolve() )
		} )
	}

	deleteDraftAfterPublishing( draftId, recentDrafts ) {
		const foundIndex = recentDrafts.findIndex( draft => draft.created === draftId )
		if ( foundIndex !== -1 ) {
			recentDrafts.splice( foundIndex, 1 )
		}

		return recentDrafts
	}


	async getPost() {
		return new Promise( async ( resolve, reject ) => {
			try {
				let post = await this.API.Media.getMedia( this.props.currentUser, this.props.routeProps.match.params.post_id )
				this.setState( { post: post }, () => { resolve() } )
			}
			catch ( e ) {
				reject( e )
			}
		} )
	}


	getDraftIdFromQueryString( query ) {
		const tentativeDraftId = QS.parse( query ).draftId
		if ( isNaN( tentativeDraftId ) ) return null

		return Number( tentativeDraftId )
	}


	createNewDraft( recentDrafts ) {
		return new Promise( ( resolve, reject ) => {
			const draftId = ( new Date() ).getTime()
			this.setState( prevState => {
				const postWithDraftId = Object.assign( prevState.post, { created: draftId } )
				return { post: postWithDraftId }
			}, () => {
				recentDrafts.push( this.state.post )
				resolve( recentDrafts )
			} )
		} )
	}

	toggleTestPost() {
		this.setState( prevState => {
			return { post: Object.assign( prevState.post, { is_test: !prevState.post.is_test } ) }
		} )
	}

	openEditTweet() {
		let newState = _cloneDeep( this.state )
		newState.edit_tweet_dialog.open = true

		this.setState( newState )
	}

	closeEditTweet( newTweet ) {
		let newState = _cloneDeep( this.state )
		newState.edit_tweet_dialog.open = false

		this.setState( newState )

		if ( newTweet ) {
			this.publishPost()
		}
	}


	async saveDraft() {
		try {
			let recentDrafts = this.storage.recentDrafts
			if ( this.state.post.created ) {
				const draftIndexToUpdate = recentDrafts.findIndex( draft => draft.created === this.state.post.created )

				if ( draftIndexToUpdate === -1 ) {
					recentDrafts = await this.createNewDraft( recentDrafts )
				}
				else {
					recentDrafts[draftIndexToUpdate] = this.state.post
				}
			}
			else {
				recentDrafts = await this.createNewDraft( recentDrafts )
			}
			this.props.showNotification( 'Draft saved' )
			this.storage.recentDrafts = recentDrafts
		}
		catch ( e ) {
			this.props.showErrorDialog( 'Saving A Draft', e )
		}
	}


	updatePostState( newPost, cb = null ) {
		Analytics.recordUserActivity()

		let newState = _cloneDeep( this.state )
		newState.post = newPost
		this.setState( newState, () => {
			if ( cb !== null ) cb()
		} )
	}


	updateUploadState( upload ) {
		let newState = _cloneDeep( this.state )
		newState.uploading = upload

		this.setState( newState )
	}


	cancelEditing() {
		const path = this.state.post.smile_content ? '/smile' : '/'
		Analytics.recordUserActivity()
		this.props.routeProps.history.replace( path )
	}

	startPublish() {
		if ( this.state.post.shortstop.twitter_sharing.cross_publish && this.state.post.shortstop.twitter_sharing.edit_tweet_in_ui ) {
			this.openEditTweet()
		}
		else {
			this.publishPost()
		}
	}

	publishPost() {
		Analytics.recordUserActivity()

		this.setState( { publishing: true }, async () => {
			try {
				let post = _cloneDeep( this.state.post )
				if ( this.isNewPost ) {
					let removedDraft = false
					if ( post.created !== undefined ) {
						let recentDrafts = this.storage.recentDrafts
						recentDrafts = this.deleteDraftAfterPublishing( post.created, recentDrafts )
						this.storage.recentDrafts = recentDrafts

						// remove the created property from the post so it is never considered a draft again
						await this.removeDraftIdFromState()
						removedDraft = true
					}

					let isBulkPost = post.smile_content && post.attachments.length > 1

					// Unwrap video object if its the only file and its not a bulk post
					if ( post.attachments.length === 1 && Array.isArray( post.attachments[0] ) ) {
						post.attachments = post.attachments[0]
						post.shortstop.media_type = 'video'
						isBulkPost = false
					}
					else if ( post.attachments.length === 1 && post.smile_content ) {
						post.shortstop.media_type = 'photo'
						isBulkPost = false
					}

					if ( isBulkPost ) {
						delete post.shortstop.media_type
					}

					if ( post.shortstop.media_type === 'text' ) {
						delete post.smile_content
					}

					await this.API.Media.createMedia( this.props.currentUser, post, isBulkPost )

					if ( removedDraft ) {
						this.props.showNotification( 'Post created and draft deleted' )
					}
					else {
						this.props.showNotification( 'Post created' )
					}

					this.setState( { post: Media.createEmptyMedia( this.props.currentUser ), publishing: false }, () => {
						if ( this.props.publishedCallback ) this.props.publishedCallback()
					} )
				}
				else {
					await this.API.Media.updateMedia( this.props.currentUser, post )
					this.props.showNotification( 'Post updated' )

					const path = this.state.post.smile_content ? '/smile' : '/'

					this.props.routeProps.history.replace( path )
				}
			}
			catch ( e ) {
				this.setState( { publishing: false }, () => {
					this.props.showErrorDialog( 'Publish Post', e )

					if ( this.props.publishedCallback ) this.props.publishedCallback()
				} )
			}
		} )
	}

	renderTestPostToggle( display = false ) {
		if ( display ) {
			return (
				<div id="testModeContainer" className={ this.props.classes.testMode }>
					<Typography variant="subtitle1" color="textPrimary" display="inline">
						Test Post
					</Typography>

					<Switch id="testModeSwitch"
						onChange={ this.toggleTestPost }
						checked={ this.state.post.is_test }
						color="primary"
					/>
				</div>
			)
		}

		return <div />
	}

	isValidDraft() {
		let { post } = this.state
		
		return helpers.isNotEmpty( post.shortstop.body_text ) ||
			helpers.isNotEmpty( post.shortstop.categories ) ||
			helpers.isNotEmpty( post.shortstop.editions ) ||
			helpers.isNotEmpty( post.attachments ) ||
			post.shortstop.event
	}

	render() {
		if ( this.state.post === null ) return ( <div /> )

		let isNewPost = ( !this.state.post._id )
		let title = ( isNewPost ) ? 'CREATE POST' : 'EDIT POST'
		
		if ( this.state.post.smile_content ) {
			title = 'SMILE UPLOAD'
		}

		let currentUser = this.props.currentUser

		let saveButtons = (
			<div style={ { display: 'flex' } }>
				<Button
					variant="contained" color="primary" component="span" className={ this.props.classes.save_button }
					disabled={ !this.state.post.isValid || this.state.publishing || this.state.uploading }
					onClick={ this.startPublish }
				>
					Save Changes
				</Button>

				<Button
					variant="contained" color="secondary" component="span" className={ this.props.classes.cancel_button }
					onClick={ this.cancelEditing }
				>
					Cancel
				</Button>
			</div>
		)

		let draftButtons = <div />

		const disableDraft = !this.isValidDraft() || ( this.state.publishing || this.state.uploading )

		if ( isNewPost ) {
			saveButtons = (
				<Button
					id="publishButton"
					variant="contained" color="primary" component="span"
					disabled={ !this.state.post.isValid || this.state.publishing || this.state.uploading }
					onClick={ this.startPublish }
					className={ this.props.classes.container_button }
				>
					Publish
				</Button>
			)

			draftButtons = (
				<Button
					id="saveDraftButton"
					variant="contained" color="secondary" component="span"
					disabled={ disableDraft }
					onClick={ this.saveDraft }
					className={ this.props.classes.container_button }
				>
					Save Draft
				</Button>
			)
		}

		return (
			<div className={ this.props.classes.div }>
				<Paper className={ this.props.classes.paper } elevation={ 1 }>
					<div className={ this.props.classes.container }>
						<div className={ this.props.classes.column }>
							<Card elevation={ 0 }>
								<CardContent className={ this.props.classes.card_content }>
									<Compose
										currentUser={ currentUser }
										showErrorDialog={ this.props.showErrorDialog }
										post={ this.state.post }
										updatePostState={ this.updatePostState }
										updateUploadState={ this.updateUploadState }
										title={ title }
										column={ helpers.isMobile() ? 0 : 1 }
										isNew={ isNewPost }
									/>
								</CardContent>
							</Card>
						</div>

						<div className={ classNames( this.props.classes.column, this.props.classes.mobilePreview ) }>

							{ this.state.post.smile_content ? (
								this.state.post.attachments.map( ( attachment, index ) => (
									<ImagePreview key={ index } media={ attachment } imageIndex={ parseInt( index ) + 1 } />
								) )
							) : (
								<PostPreview
									currentUser={ currentUser }
									post={ this.state.post }
									displayMenu={ false }
								/>
							) }

							{ this.renderTestPostToggle( currentUser.shortstop.dev_mode && isNewPost ) }

							<Compose
								currentUser={ currentUser }
								showErrorDialog={ this.props.showErrorDialog }
								post={ this.state.post }
								updatePostState={ this.updatePostState }
								updateUploadState={ this.updateUploadState }
								title={ title }
								column={ 2 }
							/>
						</div>
					</div>

					<Hidden only={ [ 'md', 'lg', 'xl' ] }>
						{ this.renderTestPostToggle( currentUser.shortstop.dev_mode && isNewPost ) }
					</Hidden>

					<div className={ this.props.classes.showPreviewButton }>
						<PostPreviewLayout
							currentUser={ currentUser }
							post={ this.state.post }
						/>
					</div>

					<EditTweet
						currentUser={ this.props.currentUser }
						showErrorDialog={ this.props.showErrorDialog }
						isOpen={ this.state.edit_tweet_dialog.open }
						closeDialog={ this.closeEditTweet }
						updatePostState={ this.updatePostState }
						post={ this.state.post }
					/>

					<div className={ this.props.classes.container_button_wrapper }>
						{ saveButtons }
						{ draftButtons }
						{ this.state.publishing && <CircularProgress size={ 24 } className={ this.props.classes.publish_progress } color="primary" /> }
					</div>
				</Paper>
			</div>
		)
	}
}


Create.propTypes = {
	currentUser			: PropTypes.object.isRequired,
	showErrorDialog		: PropTypes.func.isRequired,
	showNotification	: PropTypes.func.isRequired,

	// test hooks
	api					: PropTypes.object,
	publishedCallback	: PropTypes.func,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// provided by react router
	routeProps			: PropTypes.object.isRequired,
}


export default withStyles( styles )( Create )
