import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ShareIcon from '@material-ui/icons/Share'
import TestIcon from '@material-ui/icons/Build'

import grey from '@material-ui/core/colors/grey'

import UserAvatar from '../common/user_avatar'

import helpers from '../../lib/helpers'

const IMAGE_LOADING = 'loading'
const IMAGE_LOADED = 'loaded'
const IMAGE_UNAVAILABLE = 'unavailable'
const NO_IMAGE = 'no-image'


const styles = ( theme ) => ( {
	card			: {
		[theme.breakpoints.down( 'sm' )] : {
			minWidth: 320
		},
		maxWidth: 420,
		minWidth: 420,
		marginLeft: 1,
		marginTop: 1,
		marginRight: 1,
		display: 'inline-block'
	},
	metadata		: {
		marginLeft		: 22,
		marginRight		: 20,
		marginBottom	: 16,
		display			: 'flex',
		flexDirect		: 'row',
		alignItems		: 'center',
		flexWrap		: 'wrap'
	},
	no_metadata		: {
		marginBottom	: 20,
	},
	espn_icon		: {
		width			: 20,
		height			: 20
	},
	metadata_text	: {
		marginLeft		: 10,
		marginBottom	: 0,
		color			: grey[ 600 ]
	},
	test_icon		: {
		float			: 'right'
	},
	menu_divider	: {
		marginLeft		: 10,
		marginRight		: 10,
		marginBottom	: 10,
	},
	menu_button		: {
		marginLeft		: 10,
		marginBottom	: 10
	},
	share_button	: {
		float			: 'right',
		marginRight		: 10,
		marginBottom	: 10
	},
	button_enabled	: {
		color			: grey[ 700 ],
	},
	button_disabled	: {
		color			: grey[ 300 ],
	}
} )


class PostPreview extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			thumbnail: NO_IMAGE,
		}

		if ( this.props.post.shortstop.media_type !== 'text' ) {
			this.state = { thumbnail: IMAGE_LOADING }
		}

		this.API = null
		if ( props.api ) this.API = props.api

		this.thumbnailImage = new window.Image()

		this.thumbnailImage.onload = () => { this.setState( { thumbnail: IMAGE_LOADED } ) }
		this.thumbnailImage.onerror = () => { this.setState( { thumbnail: IMAGE_UNAVAILABLE } ) }

		this.handleEditPost = this.handleEditPost.bind( this )
		this.handleDeletePost = this.handleDeletePost.bind( this )
		this.handleSharePost = this.handleSharePost.bind( this )
	}


	componentDidMount() {
		if ( this.props.post.shortstop.media_type === 'text' ) return
		this.thumbnailImage.src = this.props.post.getThumbnailUrl()
	}


	componentDidUpdate( prevProps, prevState, snapshot ) {
		/* eslint-disable react/no-did-update-set-state */

		let previousThumbnailUrl = prevProps.post.getThumbnailUrl()
		let currentThumbnailUrl = this.props.post.getThumbnailUrl()

		if ( helpers.doesNotExist( currentThumbnailUrl ) && helpers.doesExist( previousThumbnailUrl ) ) {
			// user has removed the attachment
			this.thumbnailImage.src = null
			return this.setState( { thumbnail: NO_IMAGE } )
		}

		if ( helpers.doesNotExist( currentThumbnailUrl ) ) return	// still no attachment
		if ( currentThumbnailUrl === previousThumbnailUrl ) return	// the attachment did not change

		// retrieve the new attachment
		this.setState( { thumbnail: IMAGE_LOADING }, () => {
			this.thumbnailImage.src = currentThumbnailUrl
		} )

		/* eslint-enable react/no-did-update-set-state */
	}


	handleEditPost() {
		this.props.editPost( this.props.post )
	}


	handleDeletePost() {
		let postToDelete = _cloneDeep( this.props.post )
		this.props.deletePost( postToDelete )
	}


	handleSharePost() {
		this.props.sharePost( this.props.post )
	}

	sortCategories( prevCategory, nextCategory ) {
		let prevValue = 1
		let nextValue = 1
		if ( prevCategory.type === 'topic' ) {
			prevValue = 3
		}
		else if ( prevCategory.type === 'league' ) {
			prevValue = 2
		}

		if ( nextCategory.type === 'topic' ) {
			nextValue = 3
		}
		else if ( nextCategory.type === 'league' ) {
			nextValue = 2
		}

		return nextValue - prevValue
	}

	getPostMetadata() {
		let categories = _get( this.props.post, 'shortstop.categories', [] )
		let created = _get( this.props.post, 'created' )
		let event = _get( this.props.post, 'shortstop.event', null )
		let metadata = {
			event: null,
			categories: null
		}

		if ( event ) {
			let eventLabel = 'Event undefined'

			if ( event.short_name ) {
				eventLabel = event.short_name
			}

			if ( event.start_time ) {
				eventLabel = `${ eventLabel } (${ moment( event.start_time ).format( 'MMM Do' ) })`
			}

			metadata.event = (
				<Box m={ 0.5 }>
					<Chip label={ eventLabel } color="secondary" size="small" />
				</Box>
			)
		}
		else {
			metadata.categories = categories.sort( this.sortCategories ).map( ( category, index ) => {
				let chipColor = 'default'
				if ( category.type === 'topic' ) {
					chipColor = 'primary'
				}
				else if ( category.type === 'league' ) {
					chipColor = 'secondary'
				}

				return (
					<Box m={ 0.5 } key={ index }>
						<Tooltip title={ category.type } placement="top">
							<Chip label={ category.name } color={ chipColor } size="small" />
						</Tooltip>
					</Box>
				)
			} )
		}

		if ( created ) {
			metadata.publishTime = `Posted ${ moment( created ).fromNow() }`
		}

		return metadata
	}

	renderMetadata() {
		let metadata = this.getPostMetadata()

		if ( metadata === {} ) {
			return (
				<div className={ classNames( this.props.classes.metadata, this.props.classes.no_metadata ) }>
					<img src="https://secure.espncdn.com/redesign/assets/img/icons/icon-espn-e-@2x.png" className={ this.props.classes.espn_icon } />
				</div>
			)
		}

		return (
			<div className={ this.props.classes.metadata }>
				<img src="https://secure.espncdn.com/redesign/assets/img/icons/icon-espn-e-@2x.png" className={ this.props.classes.espn_icon } />
				{
					metadata.event ? metadata.event : metadata.categories
				}
				<div style={ { display: 'flex', alignItems: 'center' } }>
					<Typography variant="body2" paragraph className={ this.props.classes.metadata_text }>
						{ metadata.publishTime }
					</Typography>
				</div>
			</div>
		)
	}


	renderMenu() {
		if ( !this.props.displayMenu ) return ( <div /> )
		if ( !this.props.post.workflow ) return ( <div /> )

		let editButton = (
			<IconButton className={ classNames( this.props.classes.menu_button, this.props.classes.button_disabled ) } disabled>
				<EditIcon />
			</IconButton>
		)

		let deleteButton = (
			<IconButton className={ classNames( this.props.classes.menu_button, this.props.classes.button_disabled ) } disabled>
				<DeleteIcon />
			</IconButton>
		)

		let shareButton = (
			<IconButton className={ classNames( this.props.classes.share_button, this.props.classes.button_disabled ) } disabled>
				<ShareIcon />
			</IconButton>
		)

		if ( this.props.post.workflow.current_state === 'Published' || this.props.post.is_test ) {
			editButton = (
				<Tooltip title="Edit" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton
						className={ classNames( this.props.classes.menu_button, this.props.classes.button_enabled ) }
						onClick={ this.handleEditPost }
					>
						<EditIcon />
					</IconButton>
				</Tooltip>
			)

			deleteButton = (
				<Tooltip title="Delete" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton
						className={ classNames( this.props.classes.menu_button, this.props.classes.button_enabled ) }
						onClick={ this.handleDeletePost }
					>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			)

			if ( !this.props.post.is_test ) {
				shareButton = (
					<Tooltip title="Share" enterDelay={ 500 } leaveDelay={ 200 }>
						<IconButton
							className={ classNames( this.props.classes.share_button, this.props.classes.button_enabled ) }
							onClick={ this.handleSharePost }
						>
							<ShareIcon />
						</IconButton>
					</Tooltip>
				)
			}
		}

		if ( this.props.hideEditButton ) {
			editButton = <div />
		}

		if ( this.props.hideDeleteButton ) {
			deleteButton = <div />
		}

		if ( this.props.hideShareButton ) {
			shareButton = <div />
		}

		return (
			<div>
				<Divider className={ this.props.classes.menu_divider } />

				{ editButton }
				{ deleteButton }
				{ shareButton }

			</div>
		)
	}


	renderProgress() {
		if ( !this.props.displayMenu && !this.props.post.smile_content ) return ( <div /> )
		if ( !this.props.post.workflow ) return ( <div /> )
		if ( this.props.post.workflow.current_state === 'Published' ) return ( <div /> )
		if ( this.props.post.is_test ) return ( <div /> )

		return (
			<LinearProgress />
		)
	}


	renderThumbnail() {
		let thumbnail = ( <div /> )

		if ( this.props.post.shortstop.media_type === 'text' ) return thumbnail

		if ( this.state.thumbnail === IMAGE_LOADED ) {
			let imageStyle = { width: '100%', minWidth: '100%', maxWidth: '100%', display: 'block', margin: '0 auto' }
			let cardMediaStyle = {}

			let photoAttachment = this.props.post.getAttachment( 'photo' )
			let orientation = _get( photoAttachment.formats[ 0 ], 'metadata.orientation_flag', 'landscape' )
			if ( orientation === 'portrait' ) {
				imageStyle = { width: '31%', minWidth: '31%', maxWidth: '31%', display: 'block', margin: '0 auto' }
				cardMediaStyle = { backgroundColor: grey[ 200 ] }
			}

			thumbnail = (
				<div style={ cardMediaStyle }>
					<img src={ this.thumbnailImage.src } style={ imageStyle } />
				</div>
			)
		}
		else if ( this.state.thumbnail === IMAGE_LOADING || this.state.thumbnail === IMAGE_UNAVAILABLE ) {
			thumbnail = (
				<svg width="100%" style={ { background: grey[ 200 ] } } />
			)
		}

		return thumbnail
	}

	renderShowGroupButton() {
		let showGroupButton = <div />

		if ( this.props.post.smile_content && this.props.post.group_id && !this.props.hideShowGroupLink ) {
			showGroupButton = (
				<Link
					component="button"
					onClick={ () => this.props.showGroupDialog( true, this.props.post.group_id ) }
					color="secondary"
				>
					Show group
				</Link>
			)
		}

		return showGroupButton
	}

	render() {
		let smallStyles = this.props.small ? { maxWidth: 220, minWidth: 220 } : {}

		return (
			<Card className={ this.props.classes.card } style={ smallStyles }>
				<CardHeader
					avatar={ <UserAvatar currentUser={ this.props.currentUser } api={ this.API } /> }
					title={
						<div>
							{ this.props.currentUser.contact.name }
							{ this.props.post.is_test ? <TestIcon style={ { float: 'right', color: grey[700] } } /> : <div /> }
						</div>
					}
					subheader={ this.props.currentUser.contact.job_title }
				/>

				{ this.renderProgress() }

				{ this.renderThumbnail() }

				<CardContent>
					<Typography variant="body2" paragraph style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } }>
						{ this.props.post.shortstop.body_text }
					</Typography>
					{ this.renderShowGroupButton() }
				</CardContent>

				{ this.renderMetadata() }

				{ this.renderMenu() }
			</Card>
		)
	}
}


PostPreview.propTypes = {
	currentUser		: PropTypes.object.isRequired,

	// component specific
	post			: PropTypes.object.isRequired,
	displayMenu 	: PropTypes.bool.isRequired,
	deletePost		: PropTypes.func,
	sharePost		: PropTypes.func,
	editPost		: PropTypes.func,
	showGroupDialog : PropTypes.func,
	hideShowGroupLink: PropTypes.bool,
	small			: PropTypes.bool,
	hideDeleteButton: PropTypes.bool,
	hideEditButton	: PropTypes.bool,
	hideShareButton	: PropTypes.bool,

	// test hooks
	api					: PropTypes.object,

	// injected by material-ui
	classes			: PropTypes.object.isRequired,
}

PostPreview.defaultProps = {
	showGroupDialog  : () => {},
	hideShowGroupLink: false,
	hideDeleteButton: false,
	hideEditButton	: false,
	hideShareButton	: false,
}

export default withStyles( styles )( PostPreview )
