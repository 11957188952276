import AuthAPI from './api/auth'
import AWSAPI from './api/aws'
import CategoriesAPI from './api/categories'
import CommentaryAPI from './api/commentary'
import EventsAPI from './api/events'
import MediaAPI from './api/media'
import ReporterEventsAPI from './api/reporter_events'
import ScribeAPI from './api/scribe'
import SiteAPI from './api/site_api'
import UserAPI from './api/user'


export default class API {
	static get Auth() { return AuthAPI }
	static get AWS() { return AWSAPI }
	static get Categories() { return CategoriesAPI }
	static get Commentary() { return CommentaryAPI }
	static get Events() { return EventsAPI }
	static get Media() { return MediaAPI }
	static get ReporterEvents() { return ReporterEventsAPI }
	static get Scribe() { return ScribeAPI }
	static get SiteAPI() { return SiteAPI }
	static get User() { return UserAPI }
}
